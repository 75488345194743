//auth action types
export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const AUTH_MODAL_RESET = 'AUTH_MODAL_RESET';

export const FORGOT_INIT = 'FORGOT_INIT';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAIL = 'FORGOT_FAIL';

//admin action types
export const FETCH_INIT = 'FETCH_INIT';
export const FETCH_FAIL = 'FETCH_FAIL';

export const MODAL_FETCH_INIT = 'MODAL_FETCH_INIT';
export const MODAL_FETCH_FAIL = 'MODAL_FETCH_FAIL';
export const MODAL_FETCH_SUCCESS = 'MODAL_FETCH_SUCCESS';
export const MODAL_RESET = 'MODAL_RESET';

export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_AUTHORS = 'FETCH_AUTHORS';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_ADMINS = 'FETCH_ADMINS';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_POSTS = 'FETCH_POSTS';


