import * as actionTypes from '../actions/actionTypes';

const initialState = {
    posts: { data: [], count: 0 },
    category: { data: [], count: 0 },
    tags: { data: [], count: 0 },
    authors: { data: [], count: 0 },
    users: { data: [], count: 0 },
    admins: { data: [], count: 0 },
    settings: {},

    loading: false,
    error: null,

    modalLoading: false,
    modalError: null,
    modalMessage: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_INIT:
            return { ...state, loading: true, error: null, modalMessage: null };

        case actionTypes.FETCH_FAIL:
            return { ...state, loading: false, error: action.error };

        case actionTypes.MODAL_FETCH_INIT:
            return { ...state, modalLoading: true, modalError: null, modalMessage: null };

        case actionTypes.MODAL_FETCH_FAIL:
            return { ...state, modalLoading: false, modalError: action.error, modalMessage: null };

        case actionTypes.MODAL_RESET:
            return { ...state, modalLoading: false, modalError: null, modalMessage: null };

        case actionTypes.MODAL_FETCH_SUCCESS:
            return { ...state, modalLoading: false, modalError: null, modalMessage: action.message };

        case actionTypes.FETCH_POSTS:
            return { ...state, loading: false, error: null, posts: action.posts };

        case actionTypes.FETCH_CATEGORY:
            return { ...state, loading: false, error: null, category: action.category };

        case actionTypes.FETCH_TAGS:
            return { ...state, loading: false, error: null, tags: action.tags };

        case actionTypes.FETCH_AUTHORS:
            return { ...state, loading: false, error: null, authors: action.authors };

        case actionTypes.FETCH_USERS:
            return { ...state, loading: false, error: null, users: action.users };

        case actionTypes.FETCH_ADMINS:
            return { ...state, loading: false, error: null, admins: action.admins };

        case actionTypes.FETCH_SETTINGS:
            return { ...state, loading: false, error: null, settings: action.settings };

        default:
            return state;
    }
};

export default reducer;