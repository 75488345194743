import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Col,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Alert,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
    FormGroup,
    FormFeedback,
    Label
} from "reactstrap";

import Header from "components/Headers/Header.js";
import { useDispatch, useSelector } from "react-redux";
import { listAdmins, blockUnblockAdmin, addEditAdmin, modalReset } from "store/actions/admin";
import Spinner from "components/Spinner/Spinner";
import CustomModal from "components/Modal";
import { validateEmail } from "shared/helper";
import { content } from "config/content";

const SubAdminData = {
    _id: null,
    status: 1,
    first_name: '',
    last_name: '',
    email: '',
}

const SubAdmins = () => {
    const dispatch = useDispatch();
    //redux state
    const isLoading = useSelector(state => state.admin.loading);
    const isError = useSelector(state => state.admin.error);
    const admins = useSelector(state => state.admin.admins.data);
    const totalCount = useSelector(state => state.admin.admins.count);
    const isModalLoading = useSelector(state => state.admin.modalLoading);
    const modalError = useSelector(state => state.admin.modalError);
    const modalMessage = useSelector(state => state.admin.modalMessage);

    //local state
    const [subAdmin, setSubAdmin] = useState(SubAdminData);
    const [modal, setModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [limitPerPage,] = useState(25);

    const [isAdminValid, setIsAdminValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [isLastNameValid, setIsLastNameValid] = useState(false);
    const [isEmailTouched, setIsEmailTouched] = useState(false);
    const [isFirstNameTouched, setIsFirstNameTouched] = useState(false);
    const [isLastNameTouched, setIsLastNameTouched] = useState(false);



    //fetch listing on first render
    useEffect(() => {
        dispatch(listAdmins());
    }, [dispatch]);

    //reset modal states
    const resetModal = () => {
        setModal(false);
        setConfirmModal(false);
        setIsEmailValid(false);
        setIsFirstNameValid(false);
        setIsLastNameValid(false);
        setIsEmailTouched(false);
        setIsFirstNameTouched(false);
        setIsLastNameTouched(false);
        setSubAdmin(SubAdminData);
        setIsAdminValid(false);
    }

    //fetch updated list
    useEffect(() => {
        dispatch(listAdmins(
            searchQuery ? searchQuery : null,
            (limitPerPage * currentPage),
            limitPerPage));
        resetModal();
    }, [modalMessage, dispatch]);


    useEffect(() => {
        if (isEdit && (isEmailTouched || isFirstNameTouched || isLastNameTouched)) {
            setIsAdminValid(true);
        } else
            setIsAdminValid((isEmailValid && isFirstNameValid && isLastNameValid));
    }, [
        isEdit,
        isEmailValid,
        isFirstNameValid,
        isLastNameValid,
        isEmailTouched,
        isFirstNameTouched,
        isLastNameTouched
    ]);

    //Validate modal input values
    const ModalInputChangeHandler = (value, name) => {
        setSubAdmin(state => ({ ...state, [name]: value }));
        if (name === 'email') {
            setIsEmailTouched(true);
            setIsEmailValid(validateEmail(value));
        }
        if (name === 'first_name') {
            setIsFirstNameTouched(true);
            setIsFirstNameValid((value.length <= 25 && value.length >= 1));
        }
        if (name === 'last_name') {
            setIsLastNameTouched(true);
            setIsLastNameValid((value.length <= 25 && value.length >= 1));
        }
    }


    //modal open handler
    const toggleModal = () => {
        if (modal) {
            resetModal();
        } else
            setModal(true);
    }

    //Confirm modal open handler
    const toggleConfirmModal = () => {
        if (confirmModal) {
            resetModal();
        } else
            setConfirmModal(true);
    }

    //new author handler
    const addNewHandler = () => {
        dispatch(modalReset());
        setIsEdit(false);
        toggleModal();
    }

    //search admin
    const searchHandler = e => {
        e.preventDefault();
        setCurrentPage(0);
        if (searchQuery)
            dispatch(listAdmins(searchQuery));
        else
            dispatch(listAdmins());
    }

    //show block unblock confirm modal
    const blockUnblockHandler = (id, currentStatus) => {
        setSubAdmin(state => ({ ...state, _id: id, status: currentStatus }));
        setConfirmModal(true);
    }

    //update the admin status
    const blockConfirmHandler = () =>
        dispatch(blockUnblockAdmin(subAdmin._id, 1 - subAdmin.status));

    const handlePageClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
        dispatch(listAdmins(
            searchQuery ? searchQuery : null,
            (limitPerPage * index),
            limitPerPage
        ));
    };

    //edit category button 
    const editSubAdminHandler = admin => {
        setSubAdmin(admin);
        setIsEdit(true);
        toggleModal();
    }

    //save category
    const saveAdminHandler = () => {
        dispatch(addEditAdmin(subAdmin));
    }


    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                < Row >
                    <div className="col">
                        <Card className="shadow">
                            {isLoading ?
                                <Spinner />
                                :
                                isError ?
                                    <h5 style={{ color: 'red' }}>{isError}</h5>
                                    :
                                    <>
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <Col >
                                                    <Form onSubmit={searchHandler}>
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder={content.searchbarPlaceholder}
                                                                type="text"
                                                                value={searchQuery}
                                                                onChange={e => setSearchQuery(e.target.value)}
                                                            />
                                                        </InputGroup>
                                                    </Form>
                                                </Col>
                                                <Col xs="8" className="text-right">
                                                    <Button
                                                        onClick={addNewHandler}
                                                        color="primary"
                                                        size="sm"
                                                    >
                                                        Add New
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Actions</th>
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">First Name</th>
                                                    <th scope="col">Last Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Created at</th>
                                                    <th scope="col">Status</th>

                                                    {/* <th scope="col" /> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {admins.map((admin, i) => {

                                                    return (
                                                        <tr key={admin._id}>

                                                            <td >
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle
                                                                        className="btn-icon-only "
                                                                        role="button"
                                                                        href="#w"
                                                                        size="sm"
                                                                        color=""
                                                                        onClick={e => e.preventDefault()}
                                                                    >
                                                                        <i className="fas fa-cog " />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                                        <DropdownItem
                                                                            onClick={() => editSubAdminHandler(admin)}
                                                                        >
                                                                            Edit
                                                                    </DropdownItem>
                                                                        <DropdownItem
                                                                            onClick={() => blockUnblockHandler(admin._id, admin.status)}
                                                                        >
                                                                            {admin.status === 1 ? 'Block' : 'Unblock'}
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>

                                                            <td>{(limitPerPage * currentPage) + (i + 1)}</td>
                                                            <td>{admin.first_name}</td>
                                                            <td>{admin.last_name}</td>
                                                            <td>{admin.email}</td>
                                                            <td>{new Date(admin.createdAt).toLocaleString()}</td>
                                                            <td>{admin.status === 1 ? 'Active' : 'Blocked'}</td>


                                                        </tr>
                                                    )
                                                })
                                                }

                                            </tbody>
                                        </Table>
                                        <CardFooter className="py-4">
                                            <nav aria-label="...">
                                                <Pagination
                                                    className="pagination justify-content-end mb-0"
                                                    listClassName="justify-content-end mb-0"
                                                >
                                                    <PaginationItem disabled={currentPage <= 0}>
                                                        <PaginationLink
                                                            onClick={e => handlePageClick(e, currentPage - 1)}
                                                            tabIndex="-1"
                                                        >
                                                            <i className="fas fa-angle-left" />
                                                            <span className="sr-only">Previous</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(Math.ceil(totalCount / limitPerPage))].map((_, i) => (
                                                        <PaginationItem active={i === currentPage} key={i}>
                                                            <PaginationLink
                                                                onClick={e => handlePageClick(e, i)}
                                                                href="#">
                                                                {i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    ))}
                                                    <PaginationItem disabled={
                                                        (currentPage + 1) >= Math.ceil(totalCount / limitPerPage)
                                                    }>
                                                        <PaginationLink
                                                            onClick={e => handlePageClick(e, currentPage + 1)}
                                                        >
                                                            <i className="fas fa-angle-right" />
                                                            <span className="sr-only">Next</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            </nav>
                                        </CardFooter>

                                    </>
                            }
                        </Card>
                        {/* add edit category modal */}
                        <CustomModal
                            isOpen={modal}
                            toggle={toggleModal}
                            headerText={isEdit ? 'Edit Sub Admin' : 'Add New Sub Admin'}
                            primaryBtnText={isEdit ? 'Update' : 'Add'}
                            secondaryBtnText="Cancel"
                            onClick={saveAdminHandler}
                            isPrimaryDisable={!isAdminValid}
                        >
                            {isModalLoading ?
                                <Spinner />
                                :
                                <>
                                    <FormGroup row>
                                        <Label size="sm" sm={3}>First Name :</Label>
                                        <Col sm={9}>
                                            <Input
                                                invalid={!isFirstNameValid && isFirstNameTouched}
                                                bsSize="sm"
                                                placeholder="Enter First Name"
                                                type="text"
                                                value={subAdmin.first_name}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'first_name')}
                                            />
                                            <FormFeedback>length must be between 1-25</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={3}>Last Name :</Label>
                                        <Col sm={9}>
                                            <Input
                                                invalid={!isLastNameValid && isLastNameTouched}
                                                bsSize="sm"
                                                placeholder="Enter Last Name"
                                                type="text"
                                                value={subAdmin.last_name}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'last_name')}
                                            />
                                            <FormFeedback>length must be between 1-25</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={3}>Email :</Label>
                                        <Col sm={9}>
                                            <Input
                                                invalid={!isEmailValid && isEmailTouched}
                                                bsSize="sm"
                                                placeholder="Enter Email"
                                                type="text"
                                                value={subAdmin.email}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'email')}
                                            />
                                            <FormFeedback>enter a valid email</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                    {modalError &&
                                        <Alert color="danger">{modalError}</Alert>
                                    }
                                </>
                            }
                        </CustomModal>
                        {/* block unblock confirm modal */}
                        <CustomModal
                            isOpen={confirmModal}
                            toggle={toggleConfirmModal}
                            headerText={subAdmin.status ? 'Block Admin' : 'Unblock Admin'}
                            primaryBtnText="Confirm"
                            secondaryBtnText="Cancel"
                            onClick={blockConfirmHandler}
                        >
                            Are you sure you want to {subAdmin.status ? 'block' : 'unblock'} this admin?
                            {modalError &&
                                <Alert color="danger">{modalError}</Alert>
                            }
                        </CustomModal>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default SubAdmins;
