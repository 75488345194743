import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Alert,
  FormText
} from "reactstrap";
import UserHeader from "components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../store/actions/auth";
import Spinner from "components/Spinner/Spinner";
import { listSettings, addEditSettings } from "store/actions/admin";
import { authModalReset } from "store/actions/auth";

import { Editor } from '@tinymce/tinymce-react';
import { tinyEditorApiKey } from "config/api";


//default password form data
const passData = {
  old_password: '',
  new_password: '',
  confirm_new_password: '',
  isOldPassValid: false,
  isNewPassValid: false,
  isConfirmPassValid: false,
  isPasswordsValid: false
};

//default seetings form data
const settingsData = {
  privacy_policy: '',
  terms_and_condition: '',
  legal_disclaimer: '',
}

const Settings = (props) => {

  const dispatch = useDispatch();
  //redux state
  const adminSettings = useSelector(state => state.admin.settings);
  const settingsLoading = useSelector(state => state.admin.loading);
  const settingsError = useSelector(state => state.admin.error);
  const isLoading = useSelector(state => state.admin.modalLoading);
  const errorMessage = useSelector(state => state.admin.modalError);
  const successMessage = useSelector(state => state.admin.modalMessage);

  const isModalLoading = useSelector(state => state.auth.modalLoading);
  const modalMessage = useSelector(state => state.auth.modalMessage);
  const modalError = useSelector(state => state.auth.modalError);

  //local state
  const [passwords, setPasswords] = useState(passData);
  const [settings, setSettings] = useState(settingsData);
  const [isSaveEnable, setIsSaveEnable] = useState(false);

  //fetch settings data on first render
  useEffect(() => {
    dispatch(listSettings());
  }, [dispatch]);

  //set states on password change success
  useEffect(() => {
    setPasswords(passData);
    if (modalMessage) {
      setTimeout(() => {
        dispatch(authModalReset());
      }, 2000);
    }
  }, [modalMessage, dispatch]);

  //get settings data from redux
  useEffect(() => {
    setSettings(adminSettings);
  }, [adminSettings]);

  //disable button again on success
  useEffect(() => {
    setIsSaveEnable(false);
  }, [successMessage]);


  //password validations
  const passInputChangeHandler = (value, name) => {
    setPasswords(state => ({ ...state, [name]: value }));

    if (name === 'old_password')
      setPasswords(state => ({ ...state, isOldPassValid: value.length > 0 }));

    if (name === 'new_password')
      setPasswords(state => ({ ...state, isNewPassValid: value.length > 5 }));

    if (name === 'confirm_new_password')
      setPasswords(state => ({ ...state, isConfirmPassValid: value.length > 5 }));

    setPasswords(state => ({
      ...state, isPasswordsValid:
        (state.isOldPassValid && state.isNewPassValid && state.isConfirmPassValid)
        &&
        (state.new_password === state.confirm_new_password)
    }));
  };


  //on password update handler
  const updatePassHandler = () => {
    dispatch(changePassword(passwords.old_password, passwords.new_password));
  }

  //html editor input change handler
  const htmlChangeHandler = (value, name) => {
    setSettings(state => ({ ...state, [name]: value }));
    setIsSaveEnable(true);
  }

  //update settings handler
  const saveSettingsHandler = () => {
    dispatch(addEditSettings(settings));
  }

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className=" bg-secondary card-profile shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0">Change Password</h3>
                </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <FormGroup>
                  <label className="form-control-label">Old Password</label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Enter old password"
                    type="password"
                    value={passwords.old_password}
                    onChange={e => passInputChangeHandler(e.target.value, 'old_password')}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" >New Password</label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Enter new password"
                    type="password"
                    value={passwords.new_password}
                    onChange={e => passInputChangeHandler(e.target.value, 'new_password')}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" >Confirm New Password</label>
                  <Input
                    className="form-control-alternative"
                    placeholder="confirm new password"
                    type="password"
                    value={passwords.confirm_new_password}
                    onChange={e => passInputChangeHandler(e.target.value, 'confirm_new_password')}
                  />
                </FormGroup>
                <FormText>Password length must be greater than 5 </FormText>
                <br />

                {modalError &&
                  <Alert color="danger">
                    {modalError}
                  </Alert>
                }

                {modalMessage &&
                  <Alert color="success">
                    {modalMessage}
                  </Alert>
                }

                {isModalLoading ?
                  <Spinner />
                  :
                  <Button
                    color="primary"
                    disabled={!passwords.isPasswordsValid}
                    onClick={updatePassHandler}
                  >
                    Update
                   </Button>
                }

              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="shadow">
              {isLoading || settingsLoading ?
                <Spinner />
                :
                <>
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">App Settings</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="primary"
                          onClick={saveSettingsHandler}
                          disabled={!isSaveEnable}
                        >
                          Save
                      </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>

                    {(errorMessage || settingsError) &&
                      <Alert color="danger">
                        {errorMessage}
                        {settingsError}
                      </Alert>
                    }

                    {successMessage &&
                      <Alert color="success">
                        {successMessage}
                      </Alert>
                    }
                    <Form>
                      <h6 className="heading-small mb-4">Privacy Policy:</h6>
                      <div className="pl-lg-4">

                        <Editor
                          apiKey={tinyEditorApiKey}
                          init={{
                            menubar: false,
                            plugins: 'link image code paste lists wordcount advlist fullscreen',
                            toolbar: [
                              'undo redo | fontsizeselect | formatselect | forecolor backcolor | link unlink',
                              'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | image | code | fullscreen',
                            ],
                            font_formats: '',
                            content_style: "* { font-family: 'Work Sans'; }",
                            paste_as_text: true,
                            min_height: 500
                          }}
                          value={settings.privacy_policy}
                          onEditorChange={value => htmlChangeHandler(value, 'privacy_policy')}
                        />

                      </div>
                      <hr className="my-4" />
                      {/* Address */}
                      <h6 className="heading-small mb-4">Terms and Conditions:</h6>
                      <div className="pl-lg-4">

                        <Editor
                          apiKey={tinyEditorApiKey}
                          init={{
                            menubar: false,
                            plugins: 'link image code paste lists wordcount advlist fullscreen',
                            toolbar: [
                              'undo redo | fontsizeselect | formatselect | forecolor backcolor | link unlink',
                              'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | image | code | fullscreen',
                            ],
                            font_formats: '',
                            content_style: "* { font-family: 'Work Sans'; }",
                            paste_as_text: true,
                            min_height: 500
                          }}
                          value={settings.terms_and_condition}
                          onChange={e => htmlChangeHandler(e.target.getContent(), 'terms_and_condition')}
                        />

                  

                      </div>
                      <hr className="my-4" />
                      {/* Description */}
                      <h6 className="heading-small mb-4">Legal Disclaimer:</h6>
                      <div className="pl-lg-4">



                        <Editor
                          apiKey={tinyEditorApiKey}
                          init={{
                            menubar: false,
                            plugins: 'link image code paste lists wordcount advlist fullscreen',
                            toolbar: [
                              'undo redo | fontsizeselect | formatselect | forecolor backcolor | link unlink',
                              'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | image | code | fullscreen',
                            ],
                            font_formats: '',
                            content_style: "* { font-family: 'Work Sans'; }",
                            paste_as_text: true,
                            min_height: 500
                          }}
                          value={settings.legal_disclaimer}
                          onChange={e => htmlChangeHandler(e.target.getContent(), 'legal_disclaimer')}
                        />

                      

                      </div>
                    </Form>
                  </CardBody>
                </>
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Settings;
