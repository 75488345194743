import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormText,
  Container,
  FormFeedback
} from "reactstrap";

import { validateEmail } from "shared/helper";
import { useDispatch, useSelector } from 'react-redux';
import { login, forgotPassword } from "store/actions/auth";
import Spinner from "components/Spinner/Spinner";
import { Link } from "react-router-dom";

import AuthNavbar from "../components/Navbars/AuthNavbar";

const Login = (props) => {

  if (localStorage.getItem('userData')) {
    props.history.push('/admin');
  }

  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.auth.loading);
  const isError = useSelector(state => state.auth.error);
  const isModalLoading = useSelector(state => state.auth.modalLoading);
  const modalError = useSelector(state => state.auth.modalError);
  const modalMessage = useSelector(state => state.auth.modalMessage);

  //login states
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [pass, setPass] = useState('');
  const [isPassValid, setIsPassValid] = useState(false);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);

  //forgot password modal states
  const [modalEmail, setModalEmail] = useState('');
  const [isModalEmailValid, setIsModalEmailValid] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    }
  }, []);

  //modal open handler
  const toggle = () => setModal(!modal);

  //email input handler
  const emailHandler = e => {
    setEmail(e.target.value);
    setIsEmailTouched(true);
    if (!validateEmail(e.target.value)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  }

  //password input handler
  const passHandler = e => {
    setPass(e.target.value);
    setIsPasswordTouched(true);
    if (e.target.value.length <= 3) {
      setIsPassValid(false);
    } else {
      setIsPassValid(true);
    }
  }

  //forgot password modal email input handler
  const modalEmailHandler = e => {
    setModalEmail(e.target.value);
    if (validateEmail(e.target.value)) {
      setIsModalEmailValid(true);
    } else {
      setIsModalEmailValid(false);
    }
  }

  //admin login handler
  const loginHandler = () => dispatch(login(email, pass));

  //admin forgot password handler
  const forgotPasswordHandler = () => dispatch(forgotPassword(modalEmail));


  return (
    <>
      <div className="main-content">
        <AuthNavbar />
        <div className="header bg-gradient-info py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">

            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h4>LOG IN</h4>
                  </div>
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <Input
                        invalid={(!isEmailValid && isEmailTouched)}
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        value={email}
                        onChange={emailHandler}
                      />
                      <FormFeedback>Enter a valid email</FormFeedback>

                    </FormGroup>
                    <FormGroup>

                      <Input
                        invalid={(!isPassValid && isPasswordTouched)}
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        value={pass}
                        onChange={passHandler}
                      />
                      <FormFeedback>Password length must be greater than 3</FormFeedback>

                    </FormGroup>

                    {isError &&
                      <Alert color="danger">
                        {isError}
                      </Alert>
                    }

                    <div className="text-center">
                      {
                        isLoading ?
                          <Spinner />
                          :
                          <Button
                            className="my-4"
                            color="primary"
                            type="submit"
                            disabled={!(isEmailValid && isPassValid)}
                            onClick={loginHandler}
                          >
                            Sign in
                    </Button>
                      }
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <Link
                    className="text-light"
                    href="#forgot"
                    onClick={() => toggle()}
                  >
                    <small>Forgot password?</small>
                  </Link>
                </Col>
              </Row>
            </Col>

            <Modal isOpen={modal} toggle={toggle} backdrop={true}>
              <ModalHeader toggle={toggle}>Forgot Password</ModalHeader>
              <ModalBody>

                {isModalLoading ?
                  <Spinner />
                  :
                  <FormGroup>
                    <Label for="exampleEmail">Enter your email to reset your password</Label>
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      value={modalEmail}
                      onChange={modalEmailHandler}
                    />
                    <FormText>We will send you a reset password link to your registered email address.</FormText>
                    <br />

                    {modalError &&
                      <Alert color="danger">{modalError}</Alert>
                    }
                    {modalMessage &&
                      <Alert color="success">{modalMessage}</Alert>
                    }
                  </FormGroup>
                }

              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={forgotPasswordHandler}
                  disabled={(!isModalEmailValid || isModalLoading)}>
                  Reset Password
                </Button>
                {' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
              </ModalFooter>
            </Modal>

          </Row>
        </Container>
      </div>
    </>

  );
}

export default Login;
