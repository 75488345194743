export const config = {
    paginationPerPageLimitArray: [25, 35, 45, 55],
    filterPost: [
        { name: 'Select', value: 0 },
        { name: 'By Category', value: 1 },
        { name: 'By Author', value: 2 },
        { name: 'By Featured', value: 3 }
    ],
    sortPost: [
        { name: 'Select', value: 0 },
        { name: 'By Title (A-Z)', value: 1 },
        { name: 'By Title (Z-A)', value: 2 },
        { name: 'By Publish Date', value: 3 }
    ]
}