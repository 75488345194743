import * as actionTypes from '../actions/actionTypes';

const initialState = {
    user: null,
    loading: false,
    error: null,
    modalLoading: false,
    modalError: null,
    modalMessage: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.LOGIN_INIT:
            return { ...state, loading: true };

        case actionTypes.LOGIN_FAIL:
            return { ...state, loading: false, error: action.error };

        case actionTypes.LOGIN_SUCCESS:
            return { ...state, loading: false, error: null, user: action.userData };

        case actionTypes.AUTH_MODAL_RESET:
            return { ...state, modalLoading: null, modalError: null, modalMessage: null };

        case actionTypes.FORGOT_INIT:
            return { ...state, modalLoading: true, modalError: null, modalMessage: null };

        case actionTypes.FORGOT_FAIL:
            return { ...state, modalLoading: false, modalError: action.modalError, modalMessage: null };

        case actionTypes.FORGOT_SUCCESS:
            return { ...state, modalLoading: false, modalError: null, modalMessage: action.modalMessage };

        default:
            return state;
    }
};

export default reducer;