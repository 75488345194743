import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';

import AdminLayout from './layouts/Admin';
import Login from 'views/Login';
import ResetPassword from 'views/ResetPassword';

const App = (props) => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/admin" component={AdminLayout} />
                <Route path="/login" component={Login} />
                <Route path="/reset-password" component={ResetPassword} />
                <Redirect from="/" to="/login" />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
