import * as actionTypes from './actionTypes';
import axios from '../../config/axios-config';
import { endpoints } from '../../config/api';

export const authModalReset = () => ({ type: actionTypes.AUTH_MODAL_RESET });

export const login = (email, password) => {
    return dispatch => {
        dispatch({ type: actionTypes.LOGIN_INIT });
        axios.post(endpoints.adminLogin, { email, password })
            .then(res => {
                localStorage.setItem('userData', JSON.stringify(res.data));
                dispatch({ type: actionTypes.LOGIN_SUCCESS, userData: res.data });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.LOGIN_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.LOGIN_FAIL, error: err.message });
            });
    };
};

export const logout = () => {
    return (dispatch, getState) => {
        axios({
            method: 'POST',
            url: endpoints.logout,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
        })
            .then(res => {
                localStorage.removeItem('userData');
                window.location.href = '/login';
                // dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                localStorage.removeItem('userData');
                window.location.href = '/login';
                // dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
            });
    };
};

export const forgotPassword = email => {
    return dispatch => {
        dispatch({ type: actionTypes.FORGOT_INIT });
        axios.post(endpoints.forgotPassword, { email })
            .then(res => {
                dispatch({ type: actionTypes.FORGOT_SUCCESS, modalMessage: res.data.message });
            }).catch(err => {
                dispatch({ type: actionTypes.FORGOT_FAIL, modalError: err.response.data.message });
            });
    };
};

export const changePassword = (old_password, new_password) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.FORGOT_INIT });
        axios({
            method: 'POST',
            url: endpoints.changePassword,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: { old_password, new_password }
        })
            .then(res => {
                dispatch({ type: actionTypes.FORGOT_SUCCESS, modalMessage: res.data.message });
            }).catch(err => {
                dispatch({ type: actionTypes.FORGOT_FAIL, modalError: err.response.data.message });
            });
    };
};
