import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Col,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Alert,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    FormGroup,
    FormFeedback,
    Form,
    Label
} from "reactstrap";

import Header from "components/Headers/Header.js";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner/Spinner";
import CustomModal from "components/Modal";
import { addEditAuthor, modalReset, listAuthors } from "store/actions/admin";
import { validateEmail } from "shared/helper";
import { content } from "config/content";

//default Author data
const AuthorData = {
    _id: null,
    status: 1,
    first_name: '',
    last_name: '',
    bio: '',
    email: '',
    designation: '',
    organisation: '',
    facebookLink: '',
    linkedinLink: '',
    websiteLink: ''
};

const Authors = () => {
    const dispatch = useDispatch();
    //redux state
    const isLoading = useSelector(state => state.admin.loading);
    const isError = useSelector(state => state.admin.error);
    const authors = useSelector(state => state.admin.authors.data);
    const totalCount = useSelector(state => state.admin.authors.count);
    const isModalLoading = useSelector(state => state.admin.modalLoading);
    const modalError = useSelector(state => state.admin.modalError);
    const modalMessage = useSelector(state => state.admin.modalMessage);

    //local state
    const [modal, setModal] = useState(false);
    // const [confirmModal, setConfirmModal] = useState(false);
    const [author, setAuthor] = useState(AuthorData);
    const [isAuthorValid, setIsAuthorValid] = useState(false);
    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [isLastNameValid, setIsLastNameValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    // const [authorStatus, setAuthorStatus] = useState(null);
    const [isFirstNameTouched, setIsFirstNameTouched] = useState(false);
    const [isLastNameTouched, setIsLastNameTouched] = useState(false);
    const [isBioTouched, setIsBioTouched] = useState(false);
    const [isEmailTouched, setIsEmailTouched] = useState(false);
    const [isDesignationTouched, setIsDesignationTouched] = useState(false);
    const [isOrganisationTouched, setIsOrganisationTouched] = useState(false);
    const [isFacebookLinkTouched, setIsFacebookLinkTouched] = useState(false);
    const [isLinkedinLinkTouched, setIsLinkedinLinkTouched] = useState(false);
    const [isWebsiteLinkTouched, setIsWebsiteLinkTouched] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [limitPerPage,] = useState(25);


    //fetch listing on first render
    useEffect(() => {
        dispatch(listAuthors());
    }, [dispatch]);

    //reset all modal states
    const resetModal = () => {
        setModal(false);
        setAuthor(AuthorData);
        setIsFirstNameTouched(false);
        setIsFirstNameValid(false);
        setIsLastNameTouched(false);
        setIsLastNameValid(false);
        setIsBioTouched(false);
        setIsEmailTouched(false);
        setIsEmailValid(false);
        setIsDesignationTouched(false);
        setIsOrganisationTouched(false);
        setIsFacebookLinkTouched(false);
        setIsLinkedinLinkTouched(false);
        setIsWebsiteLinkTouched(false);
    }

    //fetch updated list
    useEffect(() => {
        dispatch(listAuthors(
            searchQuery ? searchQuery : null,
            (limitPerPage * currentPage),
            limitPerPage));
        resetModal();
    }, [modalMessage, dispatch]);


    //enable  add/edit button on validation checks
    useEffect(() => {
        if (isEdit && (
            isEmailTouched || isFirstNameTouched || isLastNameTouched || isBioTouched ||
            isDesignationTouched || isOrganisationTouched || isFacebookLinkTouched ||
            isLinkedinLinkTouched || isWebsiteLinkTouched)
        ) {
            setIsAuthorValid(true);
        } else
            setIsAuthorValid((isEmailValid && isFirstNameValid && isLastNameValid));
    }, [
        isEdit,
        isEmailValid,
        isFirstNameValid,
        isLastNameValid,
        isEmailTouched,
        isFirstNameTouched,
        isLastNameTouched,
        isBioTouched,
        isDesignationTouched,
        isOrganisationTouched,
        isFacebookLinkTouched,
        isLinkedinLinkTouched,
        isWebsiteLinkTouched
    ]);

    //modal open handler
    const toggleModal = () => {
        if (modal) {
            resetModal();
        } else
            setModal(true);
    }

    //Confirm modal open handler
    // const toggleConfirmModal = () => {
    //     if (confirmModal) {
    //         resetModal();
    //     } else
    //         setConfirmModal(true);
    // }

    //new author handler
    const addNewHandler = () => {
        dispatch(modalReset());
        setIsEdit(false);
        toggleModal();
    }

    //save author
    const saveAuthorHandler = () => {
        dispatch(addEditAuthor(author));
    }

    //handle modal input value change and validate checks
    const ModalInputChangeHandler = (value, name) => {
        setAuthor(state => ({ ...state, [name]: value }));
        if (name === 'email') {
            setIsEmailTouched(true);
            setIsEmailValid(validateEmail(value));
        }
        if (name === 'first_name') {
            setIsFirstNameTouched(true);
            setIsFirstNameValid((value.length <= 25 && value.length >= 2));
        }
        if (name === 'last_name') {
            setIsLastNameTouched(true);
            setIsLastNameValid((value.length <= 25 && value.length >= 2));
        }
        if (name === 'bio')
            setIsBioTouched(true);
        if (name === 'organisation')
            setIsOrganisationTouched(true);
        if (name === 'designation')
            setIsDesignationTouched(true);
        if (name === 'facebookLink')
            setIsFacebookLinkTouched(true);
        if (name === 'linkedinLink')
            setIsLinkedinLinkTouched(true);
        if (name === 'websiteLink')
            setIsWebsiteLinkTouched(true);
    }

    //edit author button 
    const editAuthorHandler = (author) => {
        setAuthor(author);
        // setAuthorName(name);
        setIsEdit(true);
        toggleModal();
    }

    //search author
    const searchHandler = e => {
        e.preventDefault();
        setCurrentPage(0);
        if (searchQuery)
            dispatch(listAuthors(searchQuery));
        else
            dispatch(listAuthors());
    }

    //show block unblock confirm modal
    // const blockUnblockHandler = (id, currentStatus) => {
    //     setAuthorStatus(currentStatus);
    //     setAuthorId(id);
    //     setConfirmModal(true);
    // }

    //update the author status
    // const blockConfirmHandler = () =>
    //     dispatch(blockUnblockAuthor(authorId, 1 - authorStatus));

    //pagination handler
    const handlePageClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
        dispatch(listAuthors(
            searchQuery ? searchQuery : null,
            (limitPerPage * index),
            limitPerPage
        ));
    };

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                < Row >
                    <div className="col">
                        <Card className="shadow">
                            {isLoading ?
                                <Spinner />
                                :
                                isError ?
                                    <h5 style={{ color: 'red' }}>{isError}</h5>
                                    :
                                    <>
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <Col >
                                                    <Form onSubmit={searchHandler}>
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder={content.searchbarPlaceholder}
                                                                type="text"
                                                                value={searchQuery}
                                                                onChange={e => setSearchQuery(e.target.value)}
                                                            />
                                                        </InputGroup>
                                                    </Form>
                                                </Col>
                                                <Col xs="8" className="text-right">
                                                    <Button
                                                        onClick={addNewHandler}
                                                        color="primary"
                                                        size="sm"
                                                    >
                                                        Add New
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Actions</th>
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">First Name</th>
                                                    <th scope="col">Last Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Bio</th>
                                                    <th scope="col">Organisation</th>
                                                    <th scope="col">Designation</th>
                                                    <th scope="col">Facebook</th>
                                                    <th scope="col">Linkedin</th>
                                                    <th scope="col">Website</th>
                                                    <th scope="col">Created At</th>
                                                    <th scope="col">Status</th>

                                                    {/* <th scope="col" /> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {authors.map((author, i) => {

                                                    return (
                                                        <tr key={author._id}>

                                                            <td >
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle
                                                                        className="btn-icon-only "
                                                                        role="button"
                                                                        href="#w"
                                                                        size="sm"
                                                                        color=""
                                                                        onClick={e => e.preventDefault()}
                                                                    >
                                                                        <i className="fas fa-cog " />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                                        <DropdownItem
                                                                            onClick={() => editAuthorHandler(author)}
                                                                        >
                                                                            Edit
                                                                    </DropdownItem>
                                                                        {/* <DropdownItem
                                                                            onClick={() => blockUnblockHandler(author._id, author.status)}
                                                                        >
                                                                            {author.status === 1 ? 'Block' : 'Unblock'}
                                                                        </DropdownItem> */}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>

                                                            <td>{(limitPerPage * currentPage) + (i + 1)}</td>
                                                            <td>{author.first_name}</td>
                                                            <td>{author.last_name}</td>
                                                            <td>{author.email}</td>
                                                            <td>{author.bio}</td>
                                                            <td>{author.organisation ? author.organisation : '-'}</td>
                                                            <td>{author.designation ? author.designation : '-'}</td>
                                                            <td>
                                                                <a
                                                                    href={author.facebookLink}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {author.facebookLink ? author.facebookLink : '-'}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a
                                                                    href={author.linkedinLink}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {author.linkedinLink ? author.linkedinLink : '-'}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a
                                                                    href={author.websiteLink}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {author.websiteLink ? author.websiteLink : '-'}
                                                                </a>
                                                            </td>
                                                            <td>{new Date(author.createdAt).toLocaleString()}</td>
                                                            <td>{author.status === 1 ? 'Active' : 'Blocked'}</td>


                                                        </tr>
                                                    )
                                                })
                                                }

                                            </tbody>
                                        </Table>
                                        <CardFooter className="py-4">
                                            <nav aria-label="...">
                                                <Pagination
                                                    className="pagination justify-content-end mb-0"
                                                    listClassName="justify-content-end mb-0"
                                                >
                                                    <PaginationItem disabled={currentPage <= 0}>
                                                        <PaginationLink
                                                            onClick={e => handlePageClick(e, currentPage - 1)}
                                                            tabIndex="-1"
                                                        >
                                                            <i className="fas fa-angle-left" />
                                                            <span className="sr-only">Previous</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(Math.ceil(totalCount / limitPerPage))].map((_, i) => (
                                                        <PaginationItem active={i === currentPage} key={i}>
                                                            <PaginationLink
                                                                onClick={e => handlePageClick(e, i)}
                                                                href="#">
                                                                {i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    ))}
                                                    <PaginationItem disabled={
                                                        (currentPage + 1) >= Math.ceil(totalCount / limitPerPage)
                                                    }>
                                                        <PaginationLink
                                                            onClick={e => handlePageClick(e, currentPage + 1)}
                                                        >
                                                            <i className="fas fa-angle-right" />
                                                            <span className="sr-only">Next</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            </nav>
                                        </CardFooter>

                                    </>
                            }
                        </Card>

                        {/* add edit author modal */}
                        <CustomModal
                            isOpen={modal}
                            toggle={toggleModal}
                            headerText={isEdit ? 'Edit Author' : 'Add New Author'}
                            primaryBtnText={isEdit ? 'Update' : 'Add'}
                            secondaryBtnText="Cancel"
                            onClick={saveAuthorHandler}
                            isPrimaryDisable={!isAuthorValid}
                        >
                            {isModalLoading ?
                                <Spinner />
                                :
                                <>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>First Name :</Label>
                                        <Col sm={8}>
                                            <Input
                                                bsSize="sm"
                                                invalid={(!isFirstNameValid && isFirstNameTouched)}
                                                placeholder="Enter First Name"
                                                type="text"
                                                value={author.first_name}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'first_name')}
                                            />
                                            <FormFeedback>length must be only between 2-25 characters</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Last Name :</Label>
                                        <Col sm={8}>
                                            <Input
                                                bsSize="sm"
                                                invalid={(!isLastNameValid && isLastNameTouched)}
                                                placeholder="Enter Last Name"
                                                type="text"
                                                value={author.last_name}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'last_name')}
                                            />
                                            <FormFeedback>length must be only between 2-25 characters</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Bio :</Label>
                                        <Col sm={8}>
                                            <Input
                                                bsSize="sm"
                                                placeholder="Enter Author Bio"
                                                type="textarea"
                                                value={author.bio}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'bio')}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Email :</Label>
                                        <Col sm={8}>
                                            <Input
                                                bsSize="sm"
                                                invalid={(!isEmailValid && isEmailTouched)}
                                                placeholder="Enter Email"
                                                type="text"
                                                value={author.email}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'email')}
                                            />
                                            <FormFeedback>Enter a valid email</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Organisation :</Label>
                                        <Col sm={8}>
                                            <Input
                                                bsSize="sm"
                                                placeholder="Enter Organisation"
                                                type="text"
                                                value={author.organisation}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'organisation')}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Designation :</Label>
                                        <Col sm={8}>
                                            <Input
                                                bsSize="sm"
                                                placeholder="Enter Designation"
                                                type="text"
                                                value={author.designation}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'designation')}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Facebook Url :</Label>
                                        <Col sm={8}>
                                            <Input
                                                bsSize="sm"
                                                placeholder="Enter Facebook Url"
                                                type="text"
                                                value={author.facebookLink}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'facebookLink')}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Linkedin Url :</Label>
                                        <Col sm={8}>
                                            <Input
                                                bsSize="sm"
                                                placeholder="Enter Linkedin Url"
                                                type="text"
                                                value={author.linkedinLink}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'linkedinLink')}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Website Url :</Label>
                                        <Col sm={8}>
                                            <Input
                                                bsSize="sm"
                                                placeholder="Enter Website Url"
                                                type="text"
                                                value={author.websiteLink}
                                                onChange={e => ModalInputChangeHandler(e.target.value, 'websiteLink')}
                                            />
                                        </Col>
                                    </FormGroup>

                                    {modalError &&
                                        <Alert color="danger">{modalError}</Alert>
                                    }
                                </>
                            }
                        </CustomModal>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Authors;
