// import Index from "views/Index.js";
// import Profile from "views/Profile.js";
// import Tables from "views/examples/Tables.js";
// import Icons from "views/examples/Icons.js";
import Users from "views/Users";
import Category from "views/Category";
import Tags from "views/Tags";
import Authors from "views/Authors";
import SubAdmins from "views/SubAdmins";
import Posts from "views/Posts";
import AddEditPost from "views/AddEditPost";
import Settings from "views/Settings";

let routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin"
  // },
  {
    path: "/posts",
    name: "Posts",
    icon: "ni ni-single-copy-04 text-blue",
    component: Posts,
    isVisible: true,
    layout: "/admin"
  },
  {
    path: "/add-edit-post",
    name: "Post",
    icon: "ni ni-single-copy-04 text-blue",
    component: AddEditPost,
    isVisible: false,
    layout: "/admin"
  },
  {
    path: "/category",
    name: "Category",
    icon: "ni ni-bullet-list-67 text-red",
    component: Category,
    isVisible: true,
    layout: "/admin"
  },
  {
    path: "/tags",
    name: "Tags",
    icon: "ni ni-tag text-green",
    component: Tags,
    isVisible: true,
    layout: "/admin"
  },
  {
    path: "/authors",
    name: "Authors",
    icon: "ni ni-badge text-blue",
    component: Authors,
    isVisible: true,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-orange",
    component: Users,
    isVisible: true,
    layout: "/admin"
  },
  {
    path: "/sub-admins",
    name: "Sub Admins",
    icon: "ni ni-satisfied text-blue",
    component: SubAdmins,
    isVisible: true,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-satisfied text-blue",
    component: Settings,
    isVisible: false,
    layout: "/admin"
  },

  //   {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   isVisible: true,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   isVisible: true,
  //   layout: "/admin"
  // },

  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
];
export default routes;
