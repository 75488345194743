export const tinyEditorApiKey = 'dxjo213hjt1wshbuab85nevoef38tclcr9gmx69p83f3r7ms';

export const baseUrl = 'https://api.peopleasset.in/';
// export const baseUrl = 'http://165.22.213.183:3000';

export const endpoints = {
    adminLogin: '/api/v1/login_admin',
    forgotPassword: '/api/v1/forgot_admin_password',
    resetPassword: '/api/v1/set_admin_password',
    changePassword: '/api/v1/change_password',
    logout: '/api/v1/logout_admin',

    listCategory: '/api/v1/list_category',
    addEditCategory: '/api/v1/add_edit_category',
    blockUnblockCategory: '/api/v1/block_category',

    listTags: '/api/v1/list_tags',
    addEditTags: '/api/v1/add_edit_tags',
    blockUnblockTags: '/api/v1/block_tags',

    listUsers: '/api/v1/list_users',
    blockUnblockUser: '/api/v1/block_user',

    listAuthors: '/api/v1/list_authors',
    addEditAuthor: '/api/v1/add_edit_author',

    listPosts: '/api/v1/list_admin_posts',
    addEditPost: '/api/v1/add_edit_post',
    blockUnblockPost: '/api/v1/block_post',
    postAction: '/api/v1/post_action',

    listAdmins: '/api/v1/list_admins',
    blockUnblockAdmin: '/api/v1/block_admin',
    addEditAdmin: '/api/v1/add_edit_admin',

    listSettings: '/api/v1/list_settings',
    addEditSettings: '/api/v1/add_edit_settings',

    uploadFile: '/api/v1/upload',
}
