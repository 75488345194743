import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Col,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Alert,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    FormGroup,
    FormFeedback,
    Form
} from "reactstrap";

import Header from "components/Headers/Header.js";
import { useDispatch, useSelector } from "react-redux";
import { listTags } from "store/actions/admin";
import Spinner from "components/Spinner/Spinner";
import CustomModal from "components/Modal";
import { addEditTags, modalReset } from "store/actions/admin";
import { blockUnblockTags } from "store/actions/admin";
import { content } from "config/content";

const Tags = () => {
    const dispatch = useDispatch();
    //redux state
    const isLoading = useSelector(state => state.admin.loading);
    const isError = useSelector(state => state.admin.error);
    const tags = useSelector(state => state.admin.tags.data);
    const totalCount = useSelector(state => state.admin.tags.count);
    const isModalLoading = useSelector(state => state.admin.modalLoading);
    const modalError = useSelector(state => state.admin.modalError);
    const modalMessage = useSelector(state => state.admin.modalMessage);

    //local state
    const [modal, setModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [tagsName, setTagsName] = useState('');
    const [tagId, setTagId] = useState(null);
    const [isTagValid, setIsTagsValid] = useState(false);
    const [tagStatus, setTagsStatus] = useState(null);
    const [isTouched, setIsTouched] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [limitPerPage, ] = useState(25);


    //fetch listing on first render
    useEffect(() => {
        dispatch(listTags());
    }, [dispatch]);

    //fetch updated list
    useEffect(() => {
        dispatch(listTags(
            searchQuery ? searchQuery : null,
            (limitPerPage * currentPage),
            limitPerPage));
        resetModal();
    }, [modalMessage, dispatch]);

    //reset modal states
    const resetModal = () => {
        setModal(false);
        setConfirmModal(false);
        setTagsName('');
        setTagId(null);
        setIsTagsValid(false);
        setTagId(null);
        setIsTouched(false);
    }

    //modal open handler
    const toggleModal = () => {
        if (modal) {
            resetModal();
        } else
            setModal(true);
    }

    //Confirm modal open handler
    const toggleConfirmModal = () => {
        if (confirmModal) {
            resetModal();
        } else
            setConfirmModal(true);
    }

    //new tags handler
    const addNewHandler = () => {
        dispatch(modalReset());
        setIsEdit(false);
        toggleModal();
    }

    //save tags
    const saveTagsHandler = () => {
        dispatch(addEditTags(tagId, tagsName));
    }

    //validate tags name
    const onTagsNameChange = e => {
        setTagsName(e.target.value);
        setIsTouched(true);
        if (e.target.value.length <= 25 && e.target.value.length >= 3) {
            setIsTagsValid(true);
        } else
            setIsTagsValid(false);
    }

    //edit tags button 
    const editTagsHandler = (id, name) => {
        setTagId(id);
        setTagsName(name);
        setIsEdit(true);
        toggleModal();
    }

    //search tags
    const searchHandler = e => {
        e.preventDefault();
        setCurrentPage(0);
        if (searchQuery)
            dispatch(listTags(searchQuery));
        else
            dispatch(listTags());
    }

    //show block unblock confirm modal
    const blockUnblockHandler = (id, currentStatus) => {
        setTagsStatus(currentStatus);
        setTagId(id);
        setConfirmModal(true);
    }

    //update the tags status
    const blockConfirmHandler = () =>
        dispatch(blockUnblockTags(tagId, 1 - tagStatus));

    const handlePageClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
        dispatch(listTags(
            searchQuery ? searchQuery : null,
            (limitPerPage * index),
            limitPerPage
        ));
    };


    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                < Row >
                    <div className="col">
                        <Card className="shadow">
                            {isLoading ?
                                <Spinner />
                                :
                                isError ?
                                    <h5 style={{ color: 'red' }}>{isError}</h5>
                                    :
                                    <>
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <Col >
                                                    <Form onSubmit={searchHandler}>
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder={content.searchbarPlaceholder}
                                                                type="text"
                                                                value={searchQuery}
                                                                onChange={e => setSearchQuery(e.target.value)}
                                                            />
                                                        </InputGroup>
                                                    </Form>
                                                </Col>
                                                <Col xs="8" className="text-right">
                                                    <Button
                                                        onClick={addNewHandler}
                                                        color="primary"
                                                        size="sm"
                                                    >
                                                        Add New
                                        </Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Actions</th>
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">Tag Name</th>
                                                    <th scope="col">Created at</th>
                                                    <th scope="col">Status</th>
                                                    
                                                    {/* <th scope="col" /> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tags.map((tag, i) => {

                                                    return (
                                                        <tr key={tag._id}>

                                                            <td >
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle
                                                                        className="btn-icon-only "
                                                                        role="button"
                                                                        href="#w"
                                                                        size="sm"
                                                                        color=""
                                                                        onClick={e => e.preventDefault()}
                                                                    >
                                                                        <i className="fas fa-cog " />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                                        <DropdownItem
                                                                            onClick={() =>
                                                                                editTagsHandler(tag._id, tag.tag_name)
                                                                            }
                                                                        >
                                                                            Edit
                                                                    </DropdownItem>
                                                                        <DropdownItem
                                                                            onClick={() => blockUnblockHandler(tag._id, tag.status)}
                                                                        >
                                                                            {tag.status === 1 ? 'Block' : 'Unblock'}
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>

                                                            <td>{(limitPerPage * currentPage) + (i + 1)}</td>
                                                            <td>{tag.tag_name}</td>
                                                            <td>{new Date(tag.createdAt).toLocaleString()}</td>
                                                            <td>{tag.status === 1 ? 'Active' : 'Blocked'}</td>

                                                            
                                                        </tr>
                                                    )
                                                })
                                                }

                                            </tbody>
                                        </Table>
                                        <CardFooter className="py-4">
                                            <nav aria-label="...">
                                                <Pagination
                                                    className="pagination justify-content-end mb-0"
                                                    listClassName="justify-content-end mb-0"
                                                >
                                                    <PaginationItem disabled={currentPage <= 0}>
                                                        <PaginationLink
                                                            onClick={e => handlePageClick(e, currentPage - 1)}
                                                            tabIndex="-1"
                                                        >
                                                            <i className="fas fa-angle-left" />
                                                            <span className="sr-only">Previous</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(Math.ceil(totalCount / limitPerPage))].map((_, i) => (
                                                        <PaginationItem active={i === currentPage} key={i}>
                                                            <PaginationLink
                                                                onClick={e => handlePageClick(e, i)}
                                                                href="#">
                                                                {i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    ))}
                                                    <PaginationItem disabled={
                                                        (currentPage + 1) >= Math.ceil(totalCount / limitPerPage)
                                                    }>
                                                        <PaginationLink
                                                            onClick={e => handlePageClick(e, currentPage + 1)}
                                                        >
                                                            <i className="fas fa-angle-right" />
                                                            <span className="sr-only">Next</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            </nav>
                                        </CardFooter>

                                    </>
                            }
                        </Card>

                        {/* add edit tags modal */}
                        <CustomModal
                            isOpen={modal}
                            toggle={toggleModal}
                            headerText={isEdit ? 'Edit Tag' : 'Add New Tag'}
                            primaryBtnText={isEdit ? 'Update' : 'Add'}
                            secondaryBtnText="Cancel"
                            onClick={saveTagsHandler}
                            isPrimaryDisable={!isTagValid}
                        >
                            {isModalLoading ?
                                <Spinner />
                                :
                                <FormGroup>
                                    <Input
                                        invalid={(!isTagValid && isTouched)}
                                        placeholder="Enter Tag Name"
                                        type="text"
                                        value={tagsName}
                                        onChange={onTagsNameChange}
                                    />
                                    <FormFeedback>length must be only between 3-25 characters</FormFeedback>
                                    <br />

                                    {modalError &&
                                        <Alert color="danger">{modalError}</Alert>
                                    }
                                </FormGroup>
                            }
                        </CustomModal>

                        {/* block unblock confirm modal */}
                        <CustomModal
                            isOpen={confirmModal}
                            toggle={toggleConfirmModal}
                            headerText={tagStatus ? 'Block Tag' : 'Unblock Tag'}
                            primaryBtnText="Confirm"
                            secondaryBtnText="Cancel"
                            onClick={blockConfirmHandler}
                        >
                            Are you sure you want to {tagStatus ? 'block' : 'unblock'} this tag?
                            {modalError &&
                                <Alert color="danger">{modalError}</Alert>
                            }
                        </CustomModal>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Tags;
