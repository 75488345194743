import React from "react";

// reactstrap components
// import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="copyright text-center text-xs text-muted">
          {/* © {new Date().getFullYear().toString()} PeopleAsset */}
          ExecHunt (India) Pvt Ltd has the exclusive and legal rights for usage of PeopleAsset as its logo/trademark.
           
            </div>
      </footer>
    );
  }
}

export default Footer;
