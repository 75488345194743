import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container,
  Alert,
  FormFeedback
} from "reactstrap";

import AuthNavbar from "../components/Navbars/AuthNavbar";
import axios from "../config/axios-config";
import { endpoints } from "../config/api";
import { Formik } from 'formik';

const ResetPassword = (props) => {

  //search for id in url
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get('id');

  //redirect if id param is not availble in url
  if (!id) {
    props.history.push('/');
  }

  //local state
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  //backgound css class
  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    }
  }, []);

  //change password handler
  const changePassHandler = values => {
    axios.post(endpoints.resetPassword, {
      _id: id,
      new_password: values.pass,
      confirm_password: values.confirmPass,
    })
      .then(res => {
        setSuccess('Password reset successfully! redirecting you to login page');
        setTimeout(() => {
          setSuccess(null);
          window.location.href = '/login';
        }, 2000);
      }).catch(err => {
        setError(err.response.data.message);
      });
  };

  return (
    <>
      <div className="main-content">
        <AuthNavbar />
        <div className="header bg-gradient-info py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">

            <Col lg="6" md="8">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h4>Reset Password</h4>
                  </div>

                  <Formik
                    initialValues={{ pass: '', confirmPass: '' }}
                    //form validations
                    validate={values => {
                      const errors = {};
                      if (!values.pass)
                        errors.pass = 'Required';
                      else if (values.pass.length <= 5)
                        errors.pass = 'length must be grater than 5';

                      if (!values.confirmPass)
                        errors.confirmPass = 'Required';
                      else if (values.confirmPass.length <= 5)
                        errors.confirmPass = 'length must be grater than 5';
                      if (values.pass !== values.confirmPass)
                        errors.confirmPass = 'Password Does not matched';

                      return errors;
                    }}
                    onSubmit={changePassHandler}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormGroup>

                            <Input
                              placeholder="New Password"
                              type="password"
                              name="pass"
                              value={values.pass}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              invalid={errors.pass}
                            />
                            <FormFeedback>
                              {errors.pass && touched.pass && errors.pass}
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup>

                            <Input
                              placeholder="Confirm New Password"
                              type="password"
                              name="confirmPass"
                              onBlur={handleBlur}
                              value={values.confirmPass}
                              onChange={handleChange}
                              invalid={errors.confirmPass}
                            />
                            <FormFeedback>
                              {errors.confirmPass && touched.confirmPass && errors.confirmPass}
                            </FormFeedback>
                          </FormGroup>

                          {error &&
                            <Alert color="danger">
                              {error}
                            </Alert>
                          }
                          {success &&
                            <Alert color="success">
                              {success}
                            </Alert>
                          }
                          <div className="text-center">
                            <Button
                              className="mt-4"
                              color="primary"
                              type="submit"
                            >
                              Change Password
                            </Button>
                          </div>
                        </Form>
                      )}
                  </Formik>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ResetPassword;