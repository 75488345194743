import * as actionTypes from './actionTypes';
import axios from '../../config/axios-config';
import { endpoints } from '../../config/api';

export const modalReset = () => ({ type: actionTypes.MODAL_RESET });

export const uploadFile = (file) => {
    return axios({
        method: 'POST',
        url: endpoints.uploadFile,
        data: file
    });
}

/////////////////////////////////// Posts actions /////////////////////////////////////////////
export const listPosts = (search, skip, limit, sort, filter, id, dateArray) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.FETCH_INIT });
        let data;
        if (dateArray)
            data = { search, skip, limit, sort, filter, id, startDate: dateArray[0], endDate: dateArray[1] };
        else
            data = { search, skip, limit, sort, filter, id };

        axios.get(endpoints.listPosts, {
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            params: data,
        })
            .then(res => {
                dispatch({ type: actionTypes.FETCH_POSTS, posts: res.data });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.message });
            });
    };
};

export const addEditPost = post => {
    return async (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });

        //alter object according to api
        let updatedTags = [];
        for (let key of post.tags) {
            updatedTags.push(key.value)
        }
        let updatedAuthors = [];
        for (let key of post.author_id) {
            updatedAuthors.push(key.value)
        }
        const updatedPost = {
            ...post,
            category_id: post.category_id._id,
            author_id: updatedAuthors,
            tags: updatedTags
        };
        if (updatedPost.pdf_link === '')
            delete updatedPost.pdf_link;
        if (updatedPost.content_credit === '')
            delete updatedPost.content_credit;
        if (updatedPost.content_original === '') {
            delete updatedPost.content_original;
            delete updatedPost.content_thumbnail;
        }
        delete updatedPost.content_accept;

        //upload content and thumbnail files to server
        if (updatedPost.thumbnail.name) {
            const formData = new FormData();
            formData.append(
                "file",
                updatedPost.thumbnail,
                updatedPost.thumbnail.name
            );
            let res = await uploadFile(formData);
            updatedPost.content_thumbnail = res.data.data;
        }
        else {
            updatedPost.content_thumbnail = updatedPost.content_link.thumbnail;
        }

        if (updatedPost.original.name) {
            const formData = new FormData();
            formData.append(
                "file",
                updatedPost.original,
                updatedPost.original.name
            );
            let res = await uploadFile(formData);
            updatedPost.content_original = res.data.data;
        }
        else {
            updatedPost.content_original = updatedPost.content_link.original;
        }

        //upload pdf file to server and save response link
        if (updatedPost.pdf.name) {
            const formData = new FormData();
            formData.append(
                "file",
                updatedPost.pdf,
                updatedPost.pdf.name
            );
            let res = await uploadFile(formData);
            updatedPost.pdf_link = res.data.data;
        }

        //deleting unneccessary keys from object
        delete updatedPost.thumbnail;
        delete updatedPost.pdf;
        delete updatedPost.original;
        delete updatedPost.content_link;

        //addEdit post to server
        axios({
            method: 'POST',
            url: endpoints.addEditPost,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: updatedPost
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};

export const blockUnblockPost = (post_id, status) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        axios({
            method: 'POST',
            url: endpoints.blockUnblockPost,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: { post_id, status }
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};

export const postAction = (type, post_id, status) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        axios({
            method: 'POST',
            url: endpoints.postAction,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: { type, post_id, status }
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};

/////////////////////////////////// Category actions /////////////////////////////////////////////
export const listCategory = (search, skip, limit) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.FETCH_INIT });
        axios.get(endpoints.listCategory, {
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            params: { search, skip, limit },
        })
            .then(res => {
                dispatch({ type: actionTypes.FETCH_CATEGORY, category: res.data });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.message });
            });
    };
};

export const addEditCategory = (id = null, category_name) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        let data;
        if (id === null) data = { category_name }
        else data = { id, category_name }
        axios({
            method: 'POST',
            url: endpoints.addEditCategory,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: data
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};

export const blockUnblockCategory = (category_id, status) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        axios({
            method: 'POST',
            url: endpoints.blockUnblockCategory,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: { category_id, status }
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};


/////////////////////////////////// Tags actions /////////////////////////////////////////////
export const listTags = (search, skip, limit) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.FETCH_INIT });
        axios.get(endpoints.listTags, {
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            params: { search, skip, limit },
        })
            .then(res => {
                dispatch({ type: actionTypes.FETCH_TAGS, tags: res.data });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.message });
            });
    };
};

export const addEditTags = (id = null, tag_name) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        let data;
        if (id === null) data = { tag_name }
        else data = { id, tag_name }
        axios({
            method: 'POST',
            url: endpoints.addEditTags,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: data
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};

export const blockUnblockTags = (tag_id, status) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        axios({
            method: 'POST',
            url: endpoints.blockUnblockTags,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: { tag_id, status }
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};


/////////////////////////////////// Authors actions /////////////////////////////////////////////
export const listAuthors = (search, skip, limit) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.FETCH_INIT });
        axios.get(endpoints.listAuthors, {
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            params: { search, skip, limit },
        })
            .then(res => {
                dispatch({ type: actionTypes.FETCH_AUTHORS, authors: res.data });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.message });
            });
    };
};

export const addEditAuthor = author => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        const {
            _id, first_name, last_name, bio, email, designation, organisation, facebookLink, linkedinLink, websiteLink
        } = author;
        let data;
        if (_id === null) data = {
            first_name, last_name, bio, email, designation, organisation, facebookLink, linkedinLink, websiteLink
        }
        else data = {
            id: _id, first_name, last_name, bio, email, designation, organisation, facebookLink, linkedinLink, websiteLink
        }
        axios({
            method: 'POST',
            url: endpoints.addEditAuthor,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: data
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};



/////////////////////////////////// Users actions /////////////////////////////////////////////
export const listUsers = (search, skip, limit) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.FETCH_INIT });
        axios.get(endpoints.listUsers, {
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            params: { search, skip, limit },
        })
            .then(res => {
                dispatch({ type: actionTypes.FETCH_USERS, users: res.data });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.message });
            });
    };
};

export const blockUnblockUser = (type, user_id, status) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        axios({
            method: 'POST',
            url: endpoints.blockUnblockUser,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: { type, user_id, status }
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};


/////////////////////////////////// sub-admins actions /////////////////////////////////////////////
export const listAdmins = (search, skip, limit) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.FETCH_INIT });
        axios.get(endpoints.listAdmins, {
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            params: { search, skip, limit },
        })
            .then(res => {
                dispatch({ type: actionTypes.FETCH_ADMINS, admins: res.data });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.FETCH_FAIL, error: err.message });
            });
    };
};

export const blockUnblockAdmin = (admin_id, status) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        axios({
            method: 'POST',
            url: endpoints.blockUnblockAdmin,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: { admin_id, status }
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};

export const addEditAdmin = ({ _id, first_name, last_name, email }) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        let data;
        if (_id === null) data = { first_name, last_name, email }
        else data = { id: _id, first_name, last_name, email }
        axios({
            method: 'POST',
            url: endpoints.addEditAdmin,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: data
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};


/////////////////////////////////// Settings actions /////////////////////////////////////////////
export const listSettings = () => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.FETCH_INIT });
        axios.get(endpoints.listSettings, {
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
        })
            .then(res => {
                dispatch({ type: actionTypes.FETCH_SETTINGS, settings: res.data });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};

export const addEditSettings = ({ privacy_policy, terms_and_condition, legal_disclaimer }) => {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.MODAL_FETCH_INIT });
        axios({
            method: 'POST',
            url: endpoints.addEditSettings,
            headers: { "authorization": `Bearer ${getState().auth.user.access_token}` },
            data: { privacy_policy, terms_and_condition, legal_disclaimer }
        })
            .then(res => {
                dispatch({ type: actionTypes.MODAL_FETCH_SUCCESS, message: res.data.message });
            }).catch(err => {
                if (err.response)
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.response.data.message });
                else
                    dispatch({ type: actionTypes.MODAL_FETCH_FAIL, error: err.message });
            });
    };
};
