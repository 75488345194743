import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormFeedback,
  FormText,
  Alert,
} from "reactstrap";
import UserHeader from "components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner/Spinner";
import { listTags, listAuthors, listCategory } from "store/actions/admin";
import Select from "react-select";
import { Formik } from "formik";
import { addEditPost } from "store/actions/admin";
import { baseUrl } from "config/api";
import { Editor } from "@tinymce/tinymce-react";
import { tinyEditorApiKey } from "config/api";

//defult post form data
const postData = {
  category_id: {
    _id: "",
    category_name: "",
  },
  submenu: "",
  tags: [],
  author_id: [],
  title: "",
  duration: 5,
  short_description: "",
  heading_content: "",
  body_content: "",
  pdf_link: "",
  guest: "",
  slug: "",
  publish_date: new Date().toISOString().substr(0, 10),
  content_original: "",
  content_thumbnail: "",
  content_credit: "",
  content_type: 1,
  publish_status: 5,
  is_featured: false,
  is_peopleasset_us: false,
  content_accept: "image/*",
  thumbnail: {},
  original: {},
  pdf: {},
};

const AddEditPost = (props) => {
  //navigate to posts if edit params not found
  const params = props.location.state;
  if (!params) {
    window.location.href = "/admin/posts";
  }

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.admin.loading);
  const isError = useSelector((state) => state.admin.error);
  const authors = useSelector((state) => state.admin.authors.data);
  const categories = useSelector((state) => state.admin.category.data);
  const subCategories = useSelector(
    (state) => state.admin.category.subCategory
  );
  const tags = useSelector((state) => state.admin.tags.data);

  const isFormSubmitting = useSelector((state) => state.admin.modalLoading);
  const errorMessage = useSelector((state) => state.admin.modalError);
  const successMessage = useSelector((state) => state.admin.modalMessage);

  //fetch dropdown listind data on first render
  useEffect(() => {
    dispatch(listAuthors());
    dispatch(listCategory());
    dispatch(listTags());
  }, [dispatch]);

  //navigate back to posts listing if success
  const { history } = props;
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        history.push("/admin/posts");
      }, 1000);
    }
  }, [successMessage, history]);

  //authors array for multiple authors select
  const authorOptions = [];
  if (authors.length > 0) {
    for (let i = 0; i < authors.length; i++) {
      authorOptions.push({
        label: authors[i].first_name + " " + authors[i].last_name,
        value: authors[i]._id,
      });
    }
  }

  //tags array for multiple tags select
  const tagOptions = [];
  if (tags.length > 0) {
    for (let i = 0; i < tags.length; i++) {
      tagOptions.push({
        label: tags[i].tag_name,
        value: tags[i]._id,
      });
    }
  }

  //submit form data to server
  const formSubmitHandler = (values) => {
    dispatch(addEditPost(values));
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow ">
              {isLoading || isFormSubmitting ? (
                <Spinner />
              ) : isError ? (
                <h5 style={{ color: "red" }}>{isError}</h5>
              ) : (
                <>
                  <Formik
                    initialValues={params.post || postData}
                    //form validations
                    validate={(values) => {
                      const errors = {};
                      if (!values.title) {
                        errors.title = "Title is Required";
                      }
                      if (values.author_id.length === 0) {
                        errors.author_id = "Author is Required";
                      }
                      if (values.tags.length === 0) {
                        errors.tags = "Tags is Required";
                      }
                      if (values.category_id._id === "") {
                        errors.category_id = "Category is Required";
                      }
                      // if (values.heading_content === '') {
                      //     errors.heading_content = 'Heading is Required';
                      // }
                      if (values.body_content === "") {
                        errors.body_content = "Body Content is Required";
                      }
                      if (values.short_description === "") {
                        errors.short_description =
                          "Short Description is Required";
                      }
                      if (!values.thumbnail.name && !params.post) {
                        errors.thumbnail = "Thumbnail photo is required";
                      }
                      if (!values.original.name && !params.post) {
                        errors.original = "Main Content file is required";
                      }
                      if (!values.slug) {
                        errors.slug = "Slug Url is required";
                      } else if (
                        !/^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(values.slug)
                      ) {
                        errors.slug = `Only ' - ' Special character is allowed!`;
                      }
                      return errors;
                    }}
                    onSubmit={formSubmitHandler}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <CardHeader className="bg-white border-0">
                          <Row className="align-items-center">
                            <Col xs="8">
                              <h3 className="mb-0">{`${params.type} Post`}</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                              <Button color="primary" type="submit">
                                {params.post ? "Update" : "Post"}
                              </Button>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {successMessage && (
                            <Alert color="success">{successMessage}</Alert>
                          )}
                          {errorMessage && (
                            <Alert color="danger">{errorMessage}</Alert>
                          )}

                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Title
                                  </label>
                                  <Input
                                    placeholder="Enter Post Title"
                                    type="text"
                                    name="title"
                                    onBlur={handleBlur}
                                    value={values.title}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(
                                        "slug",
                                        e.target.value
                                          .toLowerCase()
                                          .replace(/ /g, "-")
                                          .replace(/[^\w-]+/g, "")
                                      );
                                    }}
                                    invalid={errors.title && touched.title}
                                  />
                                  <FormFeedback>
                                    {errors.title &&
                                      touched.title &&
                                      errors.title}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Author
                                  </label>
                                  <Select
                                    isMulti
                                    isClearable
                                    placeholder="Select Author"
                                    name="author_id"
                                    onBlur={handleBlur}
                                    options={authorOptions}
                                    value={values.author_id}
                                    onChange={(v) =>
                                      handleChange({
                                        target: {
                                          name: "author_id",
                                          value: v,
                                          type: "text",
                                        },
                                      })
                                    }
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                                  <FormText color="red">
                                    {errors.author_id &&
                                      touched.author_id &&
                                      errors.author_id}
                                  </FormText>
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Tags
                                  </label>
                                  <Select
                                    isMulti
                                    isClearable
                                    closeMenuOnSelect={false}
                                    placeholder="Select Tags"
                                    name="tags"
                                    onBlur={handleBlur}
                                    options={tagOptions}
                                    value={values.tags}
                                    onChange={(v) =>
                                      handleChange({
                                        target: {
                                          name: "tags",
                                          value: v,
                                          type: "text",
                                        },
                                      })
                                    }
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                                  <FormText color="red">
                                    {errors.tags && touched.tags && errors.tags}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Category
                                  </label>
                                  <Input
                                    type="select"
                                    name="category_id._id"
                                    value={
                                      values.category_id
                                        ? values.category_id._id
                                        : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      errors.category_id && touched.category_id
                                    }
                                  >
                                    <option value="">Select</option>
                                    {categories.map((c, i) => (
                                      <option key={i} value={c._id}>
                                        {c.category_name}
                                      </option>
                                    ))}
                                  </Input>
                                  <FormFeedback>
                                    {errors.category_id && errors.category_id}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Slug URL
                                  </label>
                                  <Input
                                    type="text"
                                    name="slug"
                                    value={values.slug}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={errors.slug && touched.slug}
                                  />
                                  <FormFeedback>
                                    {errors.slug && touched.slug && errors.slug}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Sub Category
                                  </label>
                                  <Input
                                    type="select"
                                    name="submenu"
                                    value={values.submenu || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={errors.submenu && touched.submenu}
                                  >
                                    <option value="">Select</option>
                                    {subCategories &&
                                      subCategories.length &&
                                      subCategories.map((c, i) => (
                                        <option key={i} value={c.name}>
                                          {c.name}
                                        </option>
                                      ))}
                                  </Input>
                                  <FormFeedback>
                                    {errors.submenu && errors.submenu}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              {console.log({ values })}
                              <Col lg="6">
                                <label className="form-control-label">
                                  Is PeopleAsset US?
                                </label>
                                <div>
                                  <label className="custom-toggle">
                                    <input
                                      name="is_peopleasset_us"
                                      checked={values.is_peopleasset_us}
                                      type="checkbox"
                                      onChange={(e) =>
                                        setFieldValue(
                                          "is_peopleasset_us",
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <span className="custom-toggle-slider rounded-circle" />
                                  </label>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Short Description
                                  </label>
                                  <Input
                                    type="text"
                                    name="short_description"
                                    placeholder="Enter short description for post"
                                    value={values.short_description}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    invalid={
                                      errors.short_description &&
                                      touched.short_description
                                    }
                                  />
                                  <FormFeedback>
                                    {errors.short_description &&
                                      touched.short_description &&
                                      errors.short_description}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                  >
                                    Heading Content
                                  </label>
                                  <Editor
                                    apiKey={tinyEditorApiKey}
                                    init={{
                                      menubar: false,
                                      plugins:
                                        "link image code paste lists wordcount table advlist",
                                      toolbar: [
                                        "undo redo | fontsizeselect | formatselect | forecolor backcolor | bold italic underline strikethrough | link unlink",
                                        "alignleft aligncenter alignright alignjustify | bullist numlist | image | table | code",
                                      ],
                                      font_formats: "",
                                      // font_formats: 'Work Sans=Work Sans',
                                      content_style:
                                        "* { font-family: Work Sans; font-size: 14pt }",

                                      paste_as_text: true,
                                      min_height: 300,
                                    }}
                                    value={values.heading_content}
                                    onEditorChange={(value) =>
                                      handleChange({
                                        target: {
                                          name: "heading_content",
                                          value: value,
                                          type: "text",
                                        },
                                      })
                                    }
                                  />

                                  <FormText color="red">
                                    {errors.heading_content &&
                                      errors.heading_content}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Body Content
                                  </label>
                                  <Editor
                                    apiKey={tinyEditorApiKey}
                                    init={{
                                      plugins:
                                        "link image code paste lists wordcount table advlist fullscreen preview print",
                                      toolbar: [
                                        "undo redo | fontsizeselect | formatselect | forecolor backcolor | bold italic underline strikethrough | link unlink",
                                        "alignleft aligncenter alignright alignjustify | bullist numlist | image | table | code | preview |fullscreen",
                                      ],
                                      font_formats: "",
                                      content_style:
                                        "* { font-family: 'Work Sans'; }",
                                      paste_as_text: false,
                                      min_height: 600,
                                    }}
                                    value={values.body_content}
                                    onEditorChange={(value) =>
                                      handleChange({
                                        target: {
                                          name: "body_content",
                                          value: value,
                                          type: "text",
                                        },
                                      })
                                    }
                                  />

                                  <FormText color="red">
                                    {errors.body_content && errors.body_content}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Post Duration (in minutes)
                                  </label>
                                  <Input
                                    name="duration"
                                    type="number"
                                    placeholder="Enter in minutes"
                                    maxLength={3}
                                    value={values.duration}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Publish Date
                                  </label>
                                  <Input
                                    name="publish_date"
                                    value={values.publish_date}
                                    type="date"
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Publish Status
                                  </label>
                                  <Input
                                    type="select"
                                    name="publish_status"
                                    value={values.publish_status}
                                    onChange={handleChange}
                                  >
                                    <option value={1}>FOR REVIEW</option>
                                    <option value={2}>
                                      PUBLISH TO WEB ONLY
                                    </option>
                                    <option value={3}>
                                      PUBLISH TO APP ONLY
                                    </option>
                                    <option value={4}>PUBLISH TO ALL</option>
                                    <option value={5}>DRAFT</option>
                                  </Input>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Featured post
                                  </label>
                                  <Input
                                    placeholder="Is featured post?"
                                    type="select"
                                    name="is_featured"
                                    value={values.is_featured}
                                    onChange={handleChange}
                                  >
                                    <option value={false}>Non Featured</option>
                                    <option value={true}>Featured</option>
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Content Credits
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="Credits for content"
                                    name="content_credit"
                                    value={values.content_credit}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Guest
                                  </label>
                                  <Input
                                    type="text"
                                    placeholder="Enter guest"
                                    name="guest"
                                    value={values.guest}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Content Type
                                  </label>
                                  <Input
                                    type="select"
                                    name="content_type"
                                    value={values.content_type}
                                    onChange={(e) => {
                                      handleChange(e);
                                      let a =
                                        e.target.value === "1"
                                          ? "image/*"
                                          : e.target.value === "2"
                                          ? "video/*"
                                          : "audio/*";
                                      setFieldValue("content_accept", a);
                                    }}
                                  >
                                    <option value={1}>IMAGE</option>
                                    <option value={2}>VIDEO</option>
                                    <option value={3}>AUDIO</option>
                                  </Input>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Choose Main Content
                                  </label>
                                  <Input
                                    type="file"
                                    accept={values.content_accept}
                                    onChange={(e) =>
                                      handleChange({
                                        ...e,
                                        target: {
                                          name: "original",
                                          value: e.currentTarget.files[0],
                                          type: "file",
                                        },
                                      })
                                    }
                                    //  setFieldValue('original', e.currentTarget.files[0])}
                                    style={{ overflow: "hidden" }}
                                  />
                                  <FormText color="red">
                                    {errors.original && errors.original}
                                  </FormText>
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Choose Thumbnail Photo
                                  </label>
                                  <Input
                                    type="file"
                                    accept="image/*"
                                    name="thumbnail"
                                    onChange={(e) =>
                                      handleChange({
                                        ...e,
                                        target: {
                                          name: "thumbnail",
                                          value: e.currentTarget.files[0],
                                          type: "file",
                                        },
                                      })
                                    }
                                    // onChange={e => setFieldValue('thumbnail', e.currentTarget.files[0])}
                                  />
                                  <FormText color="red">
                                    {errors.thumbnail && errors.thumbnail}
                                  </FormText>
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Choose Pdf Content
                                  </label>
                                  <Input
                                    type="file"
                                    accept="application/pdf"
                                    onChange={(e) =>
                                      handleChange({
                                        ...e,
                                        target: {
                                          name: "pdf",
                                          value: e.currentTarget.files[0],
                                          type: "file",
                                        },
                                      })
                                    }
                                    // onChange={e => setFieldValue('pdf', e.currentTarget.files[0])}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            {params.post && (
                              <>
                                <hr className="my-4" />
                                <Row>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label className="form-control-label">
                                        Existing Content:
                                      </label>
                                      <br />
                                      {params.post.content_type === 1 ? (
                                        <img
                                          src={
                                            baseUrl +
                                            params.post.content_link.original
                                          }
                                          height={150}
                                          width={250}
                                          alt="..."
                                        />
                                      ) : (
                                        <video
                                          src={
                                            baseUrl +
                                            params.post.content_link.original
                                          }
                                          height={150}
                                          width={250}
                                          controls
                                        />
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label className="form-control-label">
                                        Existing Thumbnail:
                                      </label>
                                      <br />
                                      <img
                                        src={
                                          baseUrl +
                                          params.post.content_link.thumbnail
                                        }
                                        height={150}
                                        width={150}
                                        alt="..."
                                      />
                                    </FormGroup>
                                  </Col>

                                  {params.post.pdf_link !== "" && (
                                    <Col lg="4">
                                      <FormGroup>
                                        <label className="form-control-label">
                                          Existing PDF:
                                        </label>
                                        <br />
                                        <a
                                          href={baseUrl + params.post.pdf_link}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {params.post.pdf_link}
                                        </a>
                                      </FormGroup>
                                    </Col>
                                  )}
                                </Row>
                              </>
                            )}
                          </div>
                        </CardBody>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddEditPost;
