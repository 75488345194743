import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

const CustomModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} backdrop={true}>
            <ModalHeader>{props.headerText}</ModalHeader>
            <ModalBody>
                {props.children}
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    type="submit"
                    onClick={props.onClick}
                    disabled={props.isPrimaryDisable}
                >
                    {props.primaryBtnText}
                </Button>
                {' '}
                <Button color="secondary" onClick={props.toggle}>{props.secondaryBtnText}</Button>
            </ModalFooter>
        </Modal>
    );
}

export default CustomModal;