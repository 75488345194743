import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Col,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Alert,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
    FormGroup,
    Collapse,
} from "reactstrap";

// import 'rsuite/dist/styles/rsuite-default.css';
// import { DateRangePicker } from 'rsuite';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Header from "components/Headers/Header.js";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner/Spinner";
import CustomModal from "components/Modal";
import { listPosts, blockUnblockPost, postAction } from "store/actions/admin";
import moment from 'moment';
import { content } from "config/content";
import { config } from "config/config";
import { listCategory } from "store/actions/admin";
import { listAuthors } from "store/actions/admin";

const Posts = (props) => {
    const dispatch = useDispatch();
    //redux state
    const isLoading = useSelector(state => state.admin.loading);
    const isError = useSelector(state => state.admin.error);
    const posts = useSelector(state => state.admin.posts.data);
    const categories = useSelector(state => state.admin.category.data);
    const authors = useSelector(state => state.admin.authors.data);
    const totalCount = useSelector(state => state.admin.posts.count);
    const modalError = useSelector(state => state.admin.modalError);
    const modalMessage = useSelector(state => state.admin.modalMessage);

    //local state
    const [confirmBlockModal, setConfirmBlockModal] = useState(false);
    const [featuredDeleteModal, setFeaturedDeleteModal] = useState(false);
    const [postId, setPostId] = useState(null);
    const [blockStatus, setBlockStatus] = useState(null);
    const [featuredStatus, setFeaturedStatus] = useState(null);
    const [postActionType, setPostActionType] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [limitPerPage, SetLimitPerPage] = useState(25);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [sortBy, setSortBy] = useState(0);
    const [filterDateRange, setFilterDateRange] = useState(null);
    const [isCatFilterOpen, setIsCatFilterOpen] = useState(false);
    // const [selectedCatFilter, setSelectedCatFilter] = useState(0);
    const [isAuthorFilterOpen, setIsAuthorFilterOpen] = useState(false);
    const [selectedFilterId, setSelectedFilterId] = useState(0);
    

    //fetch listing on first render
    useEffect(() => {
        dispatch(listPosts());
        dispatch(listCategory());
        dispatch(listAuthors());
    }, [dispatch]);

    //fetch updated list
    useEffect(() => {
        dispatch(listPosts(
            searchQuery ? searchQuery : null,
            (limitPerPage * currentPage),
            limitPerPage, 
            sortBy,
            selectedFilter,
            selectedFilterId,
            filterDateRange,
            ));
        resetModal();
    }, [modalMessage, limitPerPage, sortBy, filterDateRange, selectedFilterId, dispatch]);

    //reset modal states
    const resetModal = () => {
        setConfirmBlockModal(false);
        setFeaturedDeleteModal(false);
        setPostId(null);
        setFeaturedStatus(null);
        setPostActionType(null);
    }

    //Confirm modal open handler
    const toggleConfirmModal = () => {
        if (confirmBlockModal) {
            resetModal();
        } else
            setConfirmBlockModal(true);
    }

    //Confirm modal open handler
    const toggleFeaturedDeleteModal = () => {
        if (featuredDeleteModal) {
            resetModal();
        } else
            setFeaturedDeleteModal(true);
    }

    //search post
    const searchHandler = e => {
        e.preventDefault();
        setCurrentPage(0);
        if (searchQuery)
            dispatch(listPosts(searchQuery));
        else
            dispatch(listPosts());
    }

    //show block unblock confirm modal
    const blockUnblockHandler = (id, currentStatus) => {
        setBlockStatus(currentStatus);
        setPostId(id);
        setConfirmBlockModal(true);
    }

    //update the post status
    const blockConfirmHandler = () =>
        dispatch(blockUnblockPost(postId, 1 - blockStatus));

    //show featured/delete confirm modal
    const addEditFeaturedHandler = (id, currentStatus) => {
        setFeaturedStatus(currentStatus);
        setPostId(id);
        setPostActionType(1);
        setFeaturedDeleteModal(true);
    }

    //update the post status (feaured/delete)
    const featuredDeleteConfirmHandler = () => {
        if (postActionType === 1)
            dispatch(postAction(postActionType, postId, !featuredStatus));
        else
            dispatch(postAction(2, postId, true));
    }

    //delete post button handler
    const deletePostHandler = (id) => {
        setPostActionType(2);
        setPostId(id);
        setFeaturedDeleteModal(true);
    }

    //handle pagination
    const handlePageClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
        dispatch(listPosts(
            searchQuery ? searchQuery : null,
            (limitPerPage * index),
            limitPerPage,
            sortBy,
            selectedFilter,
            selectedFilterId,
            filterDateRange,
        ));
    };

    const filterBy = (e) => {
        const value = e.target.value;
        setSelectedFilter(value);
        if(value === "1"){
            setIsCatFilterOpen(true);
            setIsAuthorFilterOpen(false);
        }
        else if(value==="2"){
            setIsAuthorFilterOpen(true);
            setIsCatFilterOpen(false);
        }
        else if(value==="3"){
            setSelectedFilterId(null);
            setIsCatFilterOpen(false);
            setIsAuthorFilterOpen(false);
        }
        else{
            setIsCatFilterOpen(false);
            setIsAuthorFilterOpen(false);
        }
    }

    const resetFilters = () => {
        setSortBy(0);
        setSelectedFilter(0);
        setIsCatFilterOpen(false);
        setIsAuthorFilterOpen(false);
        setFilterDateRange([]);
    }

    //navigate to addEditpost page and pass the post data
    const addEditPostHandler = (type, post = null) => {
        if (post) {
            let updatedTags = [];
            for (let key of post.tags) {
                updatedTags.push({
                    label: key.tag_name,
                    value: key._id,
                })
            }
            let updatedAuthors = [];
            for (let key of post.author_id) {
                updatedAuthors.push({
                    label: key.first_name + ' ' + key.last_name,
                    value: key._id,
                })
            }
            post.tags = updatedTags;
            post.author_id = updatedAuthors;
            post.publish_date = new Date(post.publish_date).toISOString().substr(0, 10);
            post.original = {};
            post.pdf = {};
            post.thumbnail = {};
            post.id = post._id;
            post.content_accept = post.content_type === 1 ? 'image/*' :
                post.content_type === 2 ? 'video/*' : 'audio/*';

            //deleting unneccesary keys
            delete post.updatedAt;
            delete post.createdAt;
            delete post.status;
            delete post.addedBy;
            delete post._id;

        }
        //navigate
        props.history.push('/admin/add-edit-post', { type, post });
    }


    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                < Row >
                    <div className="col">
                        <Card className="shadow">
                            {isLoading ?
                                <Spinner />
                                :
                                isError ?
                                    <h5 style={{ color: 'red' }}>{isError}</h5>
                                    :
                                    <>
                                        <CardHeader className="border-0">
                                            <Row xs="12" className="align-items-center">
                                                    <Col xs="10" >
                                                    <Row xs="8" className="align-items-center">
                                                        <Col>
                                                            <Form onSubmit={searchHandler}>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label text-xs"
                                                                    >
                                                                        Search
                                                                    </label>

                                                                    <InputGroup size="sm">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="fas fa-search" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            placeholder={content.searchbarPlaceholder}
                                                                            type="text"
                                                                            value={searchQuery}
                                                                            onChange={e => setSearchQuery(e.target.value)}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Form>
                                                        </Col>
                                                        <Col xs="3">
                                                            <FormGroup>
                                                                <label className="form-control-label text-xs">
                                                                    Records per page
                                                        </label>
                                                                <Input
                                                                    type="select"
                                                                    size="sm"
                                                                    value={limitPerPage}
                                                                    className="text-muted"
                                                                    onChange={e => SetLimitPerPage(e.target.value)}
                                                                >
                                                                    {config.paginationPerPageLimitArray.map((limit, i) => (
                                                                        <option key={i} value={limit}>
                                                                            {limit}
                                                                        </option>
                                                                    ))}}
                                                                </Input>

                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                                                                color="primary"
                                                                size="sm"
                                                            >
                                                                Add Filters
    
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                 </Col>
                                                <Col className="text-right">
                                                    <Button
                                                        onClick={() => addEditPostHandler('New')}
                                                        color="primary"
                                                        size="m"
                                                    >
                                                        Add New
                                                    </Button>
                                                </Col>

                                            </Row>
                                                    <Collapse isOpen={isFiltersOpen}>
                                                        <Row xs="12" className="align-items-center">
                                                            <Col>
                                                                <FormGroup>
                                                                    <label className="form-control-label text-xs">
                                                                        Sort
                                                                    </label>
                                                                    <Input
                                                                        type="select"
                                                                        size="sm"
                                                                        className="text-muted"
                                                                        value={sortBy}
                                                                        onChange={e => setSortBy(e.target.value)}
                                                                    >
                                                                        {config.sortPost.map((s, i) => (
                                                                            <option key={i} value={s.value}>
                                                                                {s.name}
                                                                            </option>
                                                                        ))}}
                                                                    </Input>

                                                                </FormGroup>
                                                            </Col>

                                                            <Col>
                                                                <FormGroup>
                                                                    <label className="form-control-label text-xs">
                                                                        Filter
                                                                    </label>
                                                                    <Input
                                                                        type="select"
                                                                        size="sm"
                                                                        className="text-muted"
                                                                        value={selectedFilter}
                                                                        onChange={filterBy}
                                                                    >
                                                                        {config.filterPost.map((f, i) => (
                                                                            <option key={i} value={f.value}>
                                                                                {f.name}
                                                                            </option>
                                                                        ))}}
                                                                    </Input>

                                                                </FormGroup>
                                                            </Col>
                                                            
                                                           { isCatFilterOpen ? 
                                                           <Col>
                                                                <FormGroup>
                                                                    <label className="form-control-label text-xs">
                                                                        Select Category
                                                                    </label>
                                                                    <Input
                                                                        type="select"
                                                                        size="sm"
                                                                        className="text-muted"
                                                                        value={selectedFilterId}
                                                                        onChange={e => setSelectedFilterId(e.target.value)}
                                                                    >
                                                                        <option value={0}>Select</option>
                                                                        {categories.map((c, i) => (
                                                                            <option key={i} value={c._id}>
                                                                                {c.category_name}
                                                                            </option>
                                                                        ))}}
                                                                    </Input>

                                                                </FormGroup>
                                                            </Col>
                                                            :
                                                            null}

                                                    {isAuthorFilterOpen ?
                                                        <Col>
                                                            <FormGroup>
                                                                <label className="form-control-label text-xs">
                                                                    Select Author
                                                                    </label>
                                                                <Input
                                                                    type="select"
                                                                    size="sm"
                                                                    className="text-muted"
                                                                    value={selectedFilterId}
                                                                    onChange={e => setSelectedFilterId(e.target.value)}
                                                                >
                                                                    <option value={0}>Select</option>
                                                                    {authors.map((a, i) => (
                                                                        <option key={i} value={a._id}>
                                                                            {a.first_name + " " + a.last_name}
                                                                        </option>
                                                                    ))}}
                                                                    </Input>

                                                            </FormGroup>
                                                        </Col>
                                                        :
                                                        null}
                                                            
                                                            <Col>
                                                                <FormGroup>
                                                                    <label className="form-control-label text-xs">
                                                                        Date Range
                                                                    </label><br/>
                                                                    {/* <DateRangePicker
                                                                        size="sm"
                                                                        format="DD/MM/YYYY"
                                                                        value={filterDateRange}
                                                                        onChange={v => setFilterDateRange(v)}

                                                                    
                                                                    /> */}
                                                            <DateRangePicker
                                                                // size="sm"
                                                                // format="DD/MM/YYYY"
                                                                // style={{
                                                                //     border: "thin solid lightgray",
                                                                //     borderRadius: '4px',
                                                                // }}
                                                                // className="date-range-wrapper"
                                                                value={filterDateRange}
                                                                onChange={setFilterDateRange}
                                                            
                                                            />

                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    onClick={resetFilters}
                                                                    color="secondary"
                                                                    size="sm"
                                                                >
                                                                    Reset Filters
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Collapse>
                                                
                                               
                                        </CardHeader>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Actions</th>
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">Post Title</th>
                                                    <th scope="col">Category</th>
                                                    <th scope="col">Author</th>
                                                    {/* <th scope="col" >Short Description</th> */}
                                                    <th scope="col">Duration</th>
                                                    <th scope="col">Publish Date</th>
                                                    <th scope="col">Publish Status</th>
                                                    <th scope="col">Featured</th>
                                                    <th scope="col">Status</th>

                                                </tr>

                                            </thead>
                                            <tbody>
                                                {posts.map((post, i) => {

                                                    return (
                                                        <tr
                                                            key={post._id}
                                                            onClick={addEditPostHandler.bind(this, 'Edit', post)}
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <td onClick={e => e.stopPropagation()}>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle
                                                                        className="btn-icon-only "
                                                                        role="button"
                                                                        href="#e"
                                                                        size="sm"
                                                                        color=""
                                                                        onClick={e => e.preventDefault()}
                                                                    >
                                                                        <i className="fas fa-cog " />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                                        <DropdownItem
                                                                            onClick={addEditPostHandler.bind(this, 'Edit', post)}
                                                                        >
                                                                            Edit Post
                                                                    </DropdownItem>
                                                                        <DropdownItem
                                                                            onClick={addEditFeaturedHandler.bind(this, post._id, post.is_featured)}
                                                                        >
                                                                            {post.is_featured ? 'Remove from Featured' : 'Add to Featured'}
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            onClick={blockUnblockHandler.bind(this, post._id, post.status)}
                                                                        >
                                                                            {post.status === 1 ? 'Block Post' : 'Unblock Post'}
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            onClick={deletePostHandler.bind(this, post._id)}
                                                                        >
                                                                            Delete Post
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>

                                                            <td>{(limitPerPage * currentPage) + (i + 1)}</td>
                                                            <td>{post.title}</td>
                                                            <td>{post.category_id.category_name}</td>
                                                            <td>
                                                                {post.author_id.map((author, _i) => (
                                                                    (_i > 0 ? ', ' : ' ') + author.first_name + ' ' +
                                                                    author.last_name

                                                                ))}
                                                            </td>
                                                            {/* <td style={{
                                                                maxWidth: '50px',
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap'
                                                            }}>
                                                                {post.short_description}
                                                            </td> */}
                                                            <td>{post.duration + ' mins'}</td>
                                                            <td>{moment(post.publish_date).format('MMMM DD, YYYY')}</td>

                                                            <td>
                                                                {post.publish_status === 5 ? 'DRAFT'
                                                                    : post.publish_status === 4 ? 'PUBLISH TO ALL'
                                                                        : post.publish_status === 3 ? 'PUBLISH TO APP ONLY'
                                                                            : post.publish_status === 2 ? 'PUBLISH TO WEB ONLY'
                                                                                : post.publish_status === 1 ? 'FOR REVIEW' : ''

                                                                }
                                                            </td>
                                                            <td>
                                                                {post.is_featured ? 'Featured' : 'Non Featured'}
                                                            </td>
                                                            <td>{post.status === 1 ? 'Active' : 'Blocked'}</td>


                                                        </tr>
                                                    )
                                                })
                                                }

                                            </tbody>
                                        </Table>
                                        <CardFooter className="py-4">
                                            <nav aria-label="...">
                                                <Pagination
                                                    className="pagination justify-content-end mb-0"
                                                    listClassName="justify-content-end mb-0"
                                                >
                                                    <PaginationItem disabled={currentPage <= 0}>
                                                        <PaginationLink
                                                            onClick={e => handlePageClick(e, currentPage - 1)}
                                                            tabIndex="-1"
                                                        >
                                                            <i className="fas fa-angle-left" />
                                                            <span className="sr-only">Previous</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(Math.ceil(totalCount / limitPerPage))].map((_, i) => (
                                                        <PaginationItem active={i === currentPage} key={i}>
                                                            <PaginationLink
                                                                onClick={e => handlePageClick(e, i)}
                                                                href="#">
                                                                {i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    ))}
                                                    <PaginationItem disabled={
                                                        (currentPage + 1) >= Math.ceil(totalCount / limitPerPage)
                                                    }>
                                                        <PaginationLink
                                                            onClick={e => handlePageClick(e, currentPage + 1)}
                                                        >
                                                            <i className="fas fa-angle-right" />
                                                            <span className="sr-only">Next</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            </nav>
                                        </CardFooter>

                                    </>
                            }
                        </Card>

                        {/* block unblock confirm modal */}
                        <CustomModal
                            isOpen={confirmBlockModal}
                            toggle={toggleConfirmModal}
                            headerText={blockStatus ? 'Block Post' : 'Unblock Post'}
                            primaryBtnText="Confirm"
                            secondaryBtnText="Cancel"
                            onClick={blockConfirmHandler}
                        >
                            Are you sure you want to {blockStatus ? 'block' : 'unblock'} this post?
                            {modalError &&
                                <Alert color="danger">{modalError}</Alert>
                            }
                        </CustomModal>

                        {/* featured/delete confirm modal */}
                        <CustomModal
                            isOpen={featuredDeleteModal}
                            toggle={toggleFeaturedDeleteModal}
                            headerText={
                                postActionType === 1 ? featuredStatus ?
                                    'Remove from Featured' : 'Add to Featured'
                                    :
                                    'Delete Post'
                            }
                            primaryBtnText="Confirm"
                            secondaryBtnText="Cancel"
                            onClick={featuredDeleteConfirmHandler}
                        >
                            {`Are you sure you want to `}
                            {postActionType === 1 ? featuredStatus ?
                                'remove post from Featured ?' : 'add post to Featured?'
                                :
                                'Delete Post?'
                            }

                            {modalError &&
                                <Alert color="danger">{modalError}</Alert>
                            }
                        </CustomModal>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Posts;
