import React, { useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Col,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Alert,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form
} from "reactstrap";

import Header from "components/Headers/Header.js";
import { useDispatch, useSelector } from "react-redux";
import { listUsers, blockUnblockUser } from "store/actions/admin";
import Spinner from "components/Spinner/Spinner";
import CustomModal from "components/Modal"
import { content } from "config/content";

const Users = () => {
    const dispatch = useDispatch();
    //redux state
    const isLoading = useSelector(state => state.admin.loading);
    const isError = useSelector(state => state.admin.error);
    const users = useSelector(state => state.admin.users.data);
    const totalCount = useSelector(state => state.admin.users.count);
    // const isModalLoading = useSelector(state => state.admin.modalLoading);
    const modalError = useSelector(state => state.admin.modalError);
    const modalMessage = useSelector(state => state.admin.modalMessage);

    //local state
    const [confirmModal, setConfirmModal] = useState(false);
    const [userId, setUserId] = useState(null);
    const [type, setType] = useState(null);
    const [userStatus, setUserStatus] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [limitPerPage,] = useState(25);


    //fetch listing on first render
    useEffect(() => {
        dispatch(listUsers());
    }, [dispatch]);

    //fetch updated list
    useEffect(() => {
        dispatch(listUsers(
            searchQuery ? searchQuery : null,
            (limitPerPage * currentPage),
            limitPerPage));
        resetModal();
    }, [modalMessage, dispatch]);

    //reset modal states
    const resetModal = () => {
        setConfirmModal(false);
        setUserId(null);
        setType(null);
        setUserStatus(null);
    }

    //Confirm modal open handler
    const toggleConfirmModal = () => {
        if (confirmModal) {
            resetModal();
        } else
            setConfirmModal(true);
    }

    //search user
    const searchHandler = e => {
        e.preventDefault();
        setCurrentPage(0);
        if (searchQuery)
            dispatch(listUsers(searchQuery));
        else
            dispatch(listUsers());
    }

    //show block unblock and Delete user confirm modal
    const blockUnblockHandler = (type, id, currentStatus) => {
        setType(type);
        setUserStatus(currentStatus);
        setUserId(id);
        setConfirmModal(true);
    }

    //update the user status
    const blockConfirmHandler = () => {
        //send type=1 for block/unblock user and type=2 for delete user 
        dispatch(blockUnblockUser(type, userId, 1 - userStatus));
    }

    const handlePageClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
        dispatch(listUsers(
            searchQuery ? searchQuery : null,
            (limitPerPage * index),
            limitPerPage
        ));
    };


    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                < Row >
                    <div className="col">
                        <Card className="shadow">
                            {isLoading ?
                                <Spinner />
                                :
                                isError ?
                                    <h5 style={{ color: 'red' }}>{isError}</h5>
                                    :
                                    <>
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <Col >
                                                    <Form onSubmit={searchHandler}>
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder={content.searchbarPlaceholder}
                                                                type="text"
                                                                value={searchQuery}
                                                                onChange={e => setSearchQuery(e.target.value)}
                                                            />
                                                        </InputGroup>
                                                    </Form>
                                                </Col>
                                                <Col xs="8" className="text-right">

                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                     <th scope="col">Actions</th>
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">First Name</th>
                                                    <th scope="col">Last Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Login Type</th>
                                                    <th scope="col">Created at</th>
                                                    <th scope="col">Status</th>
                                                   
                                                    {/* <th scope="col" /> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((user, i) => {

                                                    return (
                                                        <tr key={user._id}>

                                                            <td >
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle
                                                                        className="btn-icon-only "
                                                                        role="button"
                                                                        href="#w"
                                                                        size="sm"
                                                                        color=""
                                                                        onClick={e => e.preventDefault()}
                                                                    >
                                                                        <i className="fas fa-cog " />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                                        <DropdownItem
                                                                            onClick={() => blockUnblockHandler(1, user._id, user.status)}
                                                                        >
                                                                            {user.status === 1 ? 'Block User' : 'Unblock User'}
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            onClick={() => blockUnblockHandler(2, user._id, user.status)}
                                                                        >
                                                                            Delete User
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>

                                                            <td>{(limitPerPage * currentPage) + (i + 1)}</td>
                                                            <td>{user.first_name}</td>
                                                            <td>{user.last_name}</td>
                                                            <td>{user.email}</td>
                                                            <td>
                                                                {user.login_type === '1' ? 'Google'
                                                                    :
                                                                    (user.login_type === '2' ? 'Linkedin' : 'Normal')}
                                                            </td>
                                                            <td>{new Date(user.createdAt).toLocaleString()}</td>
                                                            <td>{user.status === 1 ? 'Active' : 'Blocked'}</td>

                                                            
                                                        </tr>
                                                    )
                                                })
                                                }

                                            </tbody>
                                        </Table>
                                        <CardFooter className="py-4">
                                            <nav aria-label="...">
                                                <Pagination
                                                    className="pagination justify-content-end mb-0"
                                                    listClassName="justify-content-end mb-0"
                                                >
                                                    <PaginationItem disabled={currentPage <= 0}>
                                                        <PaginationLink
                                                            onClick={e => handlePageClick(e, currentPage - 1)}
                                                            tabIndex="-1"
                                                        >
                                                            <i className="fas fa-angle-left" />
                                                            <span className="sr-only">Previous</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(Math.ceil(totalCount / limitPerPage))].map((_, i) => (
                                                        <PaginationItem active={i === currentPage} key={i}>
                                                            <PaginationLink
                                                                onClick={e => handlePageClick(e, i)}
                                                                href="#">
                                                                {i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    ))}
                                                    <PaginationItem disabled={
                                                        (currentPage + 1) >= Math.ceil(totalCount / limitPerPage)
                                                    }>
                                                        <PaginationLink
                                                            onClick={e => handlePageClick(e, currentPage + 1)}
                                                        >
                                                            <i className="fas fa-angle-right" />
                                                            <span className="sr-only">Next</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            </nav>
                                        </CardFooter>

                                    </>
                            }
                        </Card>

                        {/* block unblock confirm modal */}
                        <CustomModal
                            isOpen={confirmModal}
                            toggle={toggleConfirmModal}
                            headerText={
                                type === 1 ?
                                    userStatus ? 'Block User' : 'Unblock User'
                                    :
                                    'Delete User'
                            }
                            primaryBtnText="Confirm"
                            secondaryBtnText="Cancel"
                            onClick={blockConfirmHandler}
                        >
                            Are you sure you want to  {type === 1 ? userStatus ? 'block' : 'unblock' : 'Delete'} this user?
                            {modalError &&
                                <Alert color="danger">{modalError}</Alert>
                            }
                        </CustomModal>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Users;
