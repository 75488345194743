import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import logo from 'assets/img/brand/logo.svg';
import * as actionTypes from '../store/actions/actionTypes';

import routes from "routes.js";

class Admin extends React.Component {

  //check if redux state has user data
  componentDidMount() {
    if (!this.props.user) {
      if (localStorage.getItem('userData')) {
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.props.login(userData);
      }
      else {
        this.props.history.push('/login');
      }
    }

  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/posts",
            imgSrc: logo,
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/posts" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: (userData) => dispatch({ type: actionTypes.LOGIN_SUCCESS, userData: userData }),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Admin);
